export const countData = [
    {
      name: "订单总量",
      value: 0,
      valueNum: '0',
      provide: '单',
      icon: "success",
      color: "#2ec7c9",
    },
    {
      name: "服务金额",
      value: 0,
      valueNum: '0',
      provide: '元',
      icon: "pie-chart",
      color: "#ffb980",

    },
    {
      name: "用户数量",
      value: 0,
      valueNum: '0',
      provide: '个',
      icon: "sell",
      color: "#5ab1ef",

    },
    {
      name: "骑手数量",
      value: 0,
      valueNum: '0',
      provide: '个',
      icon: "sell",
      color: "#5ab1ef",

    },
    {
      name: "驿站数量",
      value: 0,
      valueNum: '0',
      provide: '个',
      icon: "sell",
      color: "#5ab1ef",

    },
  ]