<template>
  <div class="sustain">
    <el-card>
      <div class="mange">
          <el-input style="width:200px;margin-right: 10px;" clearable v-model="input" placeholder="请输入驿站名称"></el-input>
          <el-button type="primary" @click="inputChange">查询</el-button>
      </div>
      <div class="common-tatbel">
          <table-Data 
              :tableCrud="{index:'驿站结算'}"
              :tableData="tableData" 
              :config="config"
              @handleSusan="handleSusan">
          </table-Data>
        <div class="block">
          <div class="block-button">
            <!-- <el-button size="mini">一键冻结</el-button>
            <el-button size="mini">一键结算</el-button> -->
          </div>
          <div class="block">
            <Pagination ref="pagination" 
              :pageConfig="pageConfig" 
              @handleCurrentChange="handleCurrentChange">
            </Pagination>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination/Pagination';
import tableData from '@/components/TableData/tableData'
import { mapState } from 'vuex';
import { MessageBox, Message } from 'element-ui';
import { reqSustainList,reqBeClosing} from '@/api/accounts'
export default {
  components:{
    tableData,
    Pagination
  },
  data(){
    return{
      input:'',
      dialogVisible:false,
      sustainIndex:0,
      tableData:[],
      config:[
        {
          prop:'postName',
          label:'驿站名称'
        },
        {
          prop:'owner',
          label:'负责人'
        },
        {
          prop:'postPhone',
          label:'手机号'
        },
        {
          prop:'aliAccount',
          label:'商家号'
        },
        {
          prop:'totalOrders',
          label:'订单量'
        },
        {
          prop:'totalCost',
          label:'分润金额'
        },
        // {
        //   prop:'申请时间',
        //   label:'订单量'
        // }
      ],
      pageConfig: {
        //当前页数
        currentPage: 1,
        //每页显示数
        pageSize: 10,
        //总数据
        total: 10,
      },
    }
  },
  computed:{
    // ...mapState({
    //   tableData:state =>state.sustain.tableData
    // })
  },
  mounted(){
    this.getSustainList(this.pageConfig.pageSize,this.pageConfig.currentPage)
  },
  methods:{
    //查询 驿站申请提现列表
    getSustainList(pageSize,pageNo,phone){
      reqSustainList(pageSize,pageNo,phone).then(res=>{
        console.log(res,'驿站申请提现列表')
        if(res && res.data) {
          this.pageConfig.currentPage = res.data.page.currPage
          this.pageConfig.total = res.data.page.total
          this.tableData = res.data.page.data
        }
      })
    },
    //模糊查询 
    inputChange(){
      this.getSustainList(1,this.pageConfig.pageSize,this.input)
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.getSustainList(val,this.pageConfig.pageSize)
    },
    handleSusan(data){
      console.log(data.status,'@@')
      if(data.totalNum==0){
        Message({
          type: 'error',
          message: '该驿站没有订单要结算!'
        })
      }else{
        MessageBox.confirm('此操作将通过审核, 是否继续?', '提示',{
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(()=>{
          reqBeClosing(data.postId).then(res=>{
            console.log(res)
            if(res.code==200){
              Message({
                type: 'success',
                message: '出款成功!'
              })
              this.getSustainList(this.pageConfig.currentPage,this.pageConfig.pageSize)
            }
          })
          
        })
      }
    },
  }
}
</script>

<style lang="less" scoped>
.sustain{
  margin-left: 10px;
  .mange{
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .block{
      display: flex;
      justify-content: space-between;
      .block-button{
        margin-top: 5px;
      }
    }
}
</style>