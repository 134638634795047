import Vue from 'vue'
// import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { 
  Button, 
  Select,
  Row ,
  Col,
  Card,
  Dialog,
  Form,
  FormItem,
  Input,
  Option,
  Table,
  TableColumn,
  Pagination,
  Descriptions,
  DescriptionsItem,
  Upload,
  Container,
  Aside,
  Header,
  Main,
  Breadcrumb,
  BreadcrumbItem,
  Dropdown,
  DropdownMenu,
  Tag,
  Menu,
  MenuItem,
  Submenu,MenuItemGroup,DropdownItem,Cascader,Tabs,TabPane,Tooltip,Image,Checkbox,CheckboxGroup,InputNumber,Transfer,Drawer} from 'element-ui';
import App from './App.vue'
import router from './router'
import '@/mock/mock'
import store from './store'
import './before.js'
import Avue from '@smallwei/avue';
import '@smallwei/avue/lib/index.css';
Vue.use(Avue)
Vue.prototype.$store = store

// Vue.use(ElementUI)
Vue.use(Button)
Vue.use(Select)
Vue.use(Row)
Vue.use(Col)
Vue.use(Card)
Vue.use(Form)
Vue.use(Dialog)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Option)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Descriptions)
Vue.use(DescriptionsItem)
Vue.use(Upload)
Vue.use(Pagination)
Vue.use(Container)
Vue.use(Aside)
Vue.use(Header)
Vue.use(Main)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(Tag)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Submenu)
Vue.use(MenuItemGroup)
Vue.use(DropdownItem)
Vue.use(Cascader)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Tooltip)
Vue.use(Image)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(InputNumber)
Vue.use(Transfer)
Vue.use(Drawer)
Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
