import { getToken} from './utils/auth'
import router from './router'

router.beforeEach((to,from,next)=>{
  //判断token存不存在
  //token不存在说明没有登录，应该跳转到登录页面
  console.log(sessionStorage.getItem('postId'))
//   if(!sessionStorage.getItem('postId')){
//     next({name:'login'})
//   }else 
  if(!getToken() && to.name!=='login' && !sessionStorage.getItem('postId')){
      next({name:'login'})
  }else if(getToken()&& to.name=='login' && sessionStorage.getItem('postId')){//token 存在说明用户已经登录，应该跳转到首页
      next({name:'index'})
  }else{
      next()
  }
})