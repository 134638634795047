<template>
    <div class="member">
      <el-card>
        <div class="mange">
            <el-input style="width:200px;margin-right: 10px;" clearable v-model="input" placeholder="请输入手机号"></el-input>
            <el-button type="primary" @click="inputChange">查询</el-button>
        </div>
        <div class="common-tatbel">
          <table-Data 
              :tableCrud="{index:'反馈'}"
              :tableData="tableData" 
              :config="config"
              @handleDelete="handleDelete"
              @handleDetailed="handleDetailed">
          </table-Data>
          <div class="block">
            <Pagination ref="pagination" 
              :pageConfig="pageConfig" 
              @handleCurrentChange="handleCurrentChange">
            </Pagination>
          </div>
        </div>
      </el-card>
    </div>
  </template>
  
  <script>
  import Pagination from '@/components/Pagination/Pagination';
  import tableData from '@/components/TableData/tableData'
  import { MessageBox, Message } from 'element-ui';
  import { reqRecord,reqRecordDel} from '@/api/user';
  export default {
      components:{
        tableData,
        Pagination
      },
      data(){
          return{
              input:'',
              pageConfig: {
                //当前页数
                currentPage: 1,
                //每页显示数
                pageSize: 10,
                //总数据
                total: 10,
              },
              tableData:[],
              config:[
              {
                prop:'courierId',
                label:'反馈ID'
              },
              {
                prop:'suggestContent',
                label:'反馈内容'
              },
              {
                prop:'createTime',
                label:'反馈时间'
              },
            ]
          }
      },
      computed:{
          // ...mapState({
          //     memberData:state =>state.member.memberData
          // })
      },
      mounted(){
        this.getMemberList(this.pageConfig.currentPage,this.pageConfig.pageSize)
      },
      methods:{
         //查询 意见反馈列表
          getMemberList(pageSize,pageNo,userId){
            reqRecord(pageSize,pageNo,userId).then(res=>{
              console.log(res,'意见反馈列表')
              this.pageConfig.currentPage = res.data.page.currPage
              this.pageConfig.total = res.data.page.total
              this.tableData = res.data.page.list
            })
          },
          //模糊查询 
          inputChange(){
            this.getMemberList(this.pageConfig.currentPage,this.pageConfig.pageSize,this.input)
          },
          handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
          },
          handleCurrentChange(val) {
            this.getMemberList(val,this.pageConfig.pageSize)
          },
          //启用
          userUpFreeze(data){
          },
          handleDetailed(row){
            console.log(row)
            this.drawer = true
            row.courierDetails.forEach(item=>{
              item.receiverTime = row.createTime,
              item.completedTime = row.updateTime
            })
            this.addressee = row.orderDetails
            this.receiver = row.courierDetails
          },
          //删除
          handleDelete(item){
            reqRecordDel(item.suggestId).then(res=>{
              if(res.code==200){
                Message({
                  type: 'success',
                  message: '删除成功!'
                })
                this.getMemberList(this.pageConfig.currentPage,this.pageConfig.pageSize)
              }
            })
          }
      }
  }
  </script>
  
  <style lang="less" scoped>
  .member{
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      .mange{
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-bottom: 10px;
    }
    .block{
        display: flex;
        justify-content: flex-end;
      }
  }
  
  </style>