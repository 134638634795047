<template>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="this.pageConfig.currentPage"
        :total="this.pageConfig.total">
    </el-pagination>
</template>

<script>
export default {
    props:{
        pageConfig:{
            type:Object,
            default(){
                return {
                    currentPage:'1',
                    pageSize:'10',
                    total:'10'
                }
            }
        },  
    },
    data(){
        return{

        }
    },
    methods:{
        handleCurrentChange(val){
            this.$emit('handleCurrentChange',val)
        }
    }
}
</script>

<style>

</style>