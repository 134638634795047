<template>
    <div class="member">
      <el-card>
        <div class="mange">
            <el-input style="width:200px;margin-right: 10px;" clearable v-model="input" placeholder="请输入姓名"></el-input>
            <el-button type="primary" @click="inputChange">查询</el-button>
        </div>
        <div class="common-tatbel">
          <table-Data 
              :tableCrud="{index:'服务'}"
              :tableData="tableData" 
              :config="config"
              @handleAgree="handleAgree">
          </table-Data>
          <div class="block">
            <Pagination ref="pagination" 
              :pageConfig="pageConfig" 
              @handleCurrentChange="handleCurrentChange">
            </Pagination>
          </div>
        </div>
      </el-card>
    </div>
  </template>
  
  <script>
  import Pagination from '@/components/Pagination/Pagination';
  import tableData from '@/components/TableData/tableData'
  import { MessageBox, Message } from 'element-ui';
  import { reqStageInfo,reqStageInfoRefuse,reqUpFreeze} from '@/api/stage';
  export default {
      components:{
        tableData,
        Pagination
      },
      data(){
          return{
              input:'',
              pageConfig: {
                //当前页数
                currentPage: 1,
                //每页显示数
                pageSize: 10,
                //总数据
                total: 10,
              },
              tableData:[],
              config:[
                {
                  prop:'name',
                  label:'姓名'
                },
                {
                  prop:'phone',
                  label:'手机号'
                },
                {
                  prop:'arrayName',
                  label:'身份证号'
                },
                {
                  prop:'status',
                  label:'状态'
                },
                {
                  prop:'createTime',
                  label:'创建时间'
                },
              ],
          }
      },
      computed:{
          // ...mapState({
          //     memberData:state =>state.member.memberData
          // })
      },
      mounted(){
        this.getMemberList(this.pageConfig.currentPage,this.pageConfig.pageSize,sessionStorage.getItem('postId'),'')
      },
      methods:{
         //查询 骑手申请驿站列表
          getMemberList(pageSize,pageNo,sysUserId,name){
            reqStageInfo(pageSize,pageNo,sysUserId,name).then(res=>{
              console.log(res,'申请驿站列表')
              res.data.page.list.forEach(item=>{
                if(item.post == null){
                  item.post = {}
                }
              })
              this.pageConfig.currentPage = res.data.page.currPage
              this.pageConfig.total = res.data.page.total
              this.tableData = res.data.page.list
            })
          },
          //模糊查询 
          inputChange(){
            this.getMemberList(1,this.pageConfig.pageSize,sessionStorage.getItem('postId'),this.input)
          },
          handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
          },
          handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.getMemberList(val,this.pageConfig.pageSize,sessionStorage.getItem('postId'))
          },
          //同意
          handleAgree(data,index){
            console.log(data)
            if(data.status !=0){
                Message({
                type:'info',
                message:'当前订单状态已确定，请勿重复操作'
                })
            }else{
                MessageBox.confirm(index==1?'此操作将同意该骑手申请！是否继续？':'此操作将拒绝该骑手申请！是否继续？','提示',{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(()=>{
                    reqStageInfoRefuse(data.courierPostId,index,sessionStorage.getItem('postId')).then(res=>{
                    if(res.code==200){
                    Message({
                        type:'success',
                        message:index==1?'同意成功':'拒绝成功'
                    })
                    this.getMemberList(this.pageConfig.currentPage,this.pageConfig.pageSize,sessionStorage.getItem('postId'))
                    }
                })
            })
            }
          },
          //启用
          userUpFreeze(data){
            
          },
      }
  }
  </script>
  
  <style lang="less" scoped>
  .member{
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      .mange{
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-bottom: 10px;
        }
        .block{
          display: flex;
          justify-content: flex-end;
        }
  }
  
  </style>