<template>
  <div class="personal">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>个人信息</span>
      </div>
      <div class="text_item">
        <img class="item_img" src="@/assets/image/椭圆形@2x.png">
        
      </div>
      <div>
        <ul class="list-group">
          <li class="list-group-item">
            <div :class="`el-icon-user-solid`">用户昵称</div>
            <div class="pull-right">{{userAdmin.sysUserName}}</div>
          </li>
          <li class="list-group-item">
            <div :class="`el-icon-phone`">密码</div>
            <div class="pull-right">{{userAdmin.sysPassword}}</div>
          </li>
          <li class="list-group-item">
            <div :class="`el-icon-s-comment`">用户邮箱</div>
            <div class="pull-right"></div>
          </li>
          <li class="list-group-item">
            <div :class="`el-icon-s-custom`">所属角色</div>
            <div class="pull-right">
              {{userAdmin.sysUserId==1?'超级管理员':'普通管理员'}}
            </div>
          </li>
          <li class="list-group-item">
            <div :class="`el-icon-s-claim`">创建日期</div>
            <div class="pull-right">{{userAdmin.updateTime}}</div>
          </li>
        </ul>
      </div>
    </el-card>
    <el-tabs type="border-card" style="margin-left:10px;width:50%">
      <el-tab-pane label="修改密码">
        <div class="pane-item">
          <el-form label-width="100px" :model="form" :rules="rules" ref="form">
            <el-form-item label="原密码:" prop="oldPassword" style="width:500px">
              <el-input v-model="form.oldPassword" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="新密码:" prop="xinPassword" style="width:500px">
              <el-input v-model="form.xinPassword" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="personalUpdate">提交修改</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import admin from '@/store/modules/admin'
import { reqUpDataUser} from '@/api/login'
import { Message } from 'element-ui';
import Cookie from 'js-cookie'
export default {
  data() {
      return {
        dialogVisible: false,
        form:{
          oldPassword:'',
          xinPassword:''
        },
        rules:{
          oldPassword:[
            { required: true, message: '请输入原密码' }
          ],
          xinPassword:[
            { required: true, message: '请输入新密码' }
          ]
        }
      };
    },
    computed:{
      ...mapState({
        userAdmin:state =>state.admin.userAdmin
      })
    },
    methods: {
      personalUpdate(){
        this.$refs.form.validate((val)=>{
          if(val){
            reqUpDataUser(this.form.xinPassword,this.form.oldPassword).then(res=>{
              console.log(res,'修改密码')
              if(res.code==200){
                Message({
                  type:'success',
                  message:'修改密码成功！'
                })
                Cookie.remove('token')
                this.$router.push('/login')
              }
            })
          }
        })
      }
    }
}
</script>

<style lang="less" scoped>
.personal{
  margin-left: 10px;
  display: flex;
  .pane-item{
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .box-card{
    width: 30%;
    .text_item{
      display: flex;
      justify-content: center;
      .item_img{
        width:100px;
        height: 100px;
      }
    }
    .list-group{
        display: flex;
        flex-direction: column;
        .list-group-item{
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 40px;
          border-bottom: 1px solid #ccc;

        }
      }
  }
  
}
</style>