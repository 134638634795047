<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
   
  },
  mounted(){
    if(!sessionStorage.getItem('postId')){
      this.$router.push('/login')
    }
    if (window.performance.navigation.type == 1) {
      if(this.$route.meta.label!='首页'){
        this.$store.commit('updateMenu',{label:this.$route.meta.label,path:this.$route.path,name:this.$route.name})
        this.$store.commit('headerMenu',{label:this.$route.meta.label,path:this.$route.path,name:this.$route.name})
      }
    }
  }
}
</script>

<style>
html,body{
  margin: 0;
  padding: 0;
}
.el-tag{
  cursor: pointer;
}
.block{
  margin-top: 10px;
}
.el-table{
  height: 100%;
}
</style>
