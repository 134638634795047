import request from '@/utils/request'

//骑手列表 模糊查询
export function reqUserList(pageSize, pageNo, query) {
    return request({
        url: `/win/courier/courierPage/${pageSize}/${pageNo}`,
        method: 'POST',
        params: query
    })
}

//骑手 冻结/启用
export function reqFreeze(courierId) {
    return request({
        url: `/win/courier/freeze`,
        method: 'POST',
        params: {
            courierId
        }
    })
}

//骑手 启用
export function reqUpFreeze(courierId) {
    return request({
        url: `/win/courier/unFreeze`,
        method: 'POST',
        params: {
            courierId
        }
    })
}

//意见反馈列表
export function reqRecord(pageSize, pageNo, userId) {
    return request({
        url: `/fm/suggest/allSuggest/${pageSize}/${pageNo}`,
        method: 'POST',
        params: {
            userId
        }
    })
}

//删除意见
export function reqRecordDel(suggestId) {
    return request({
        url: `/fm/suggest/isDelete`,
        method: 'GET',
        params: {
            suggestId
        }
    })
}
