import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'

// create an axios instance
const http = axios.create({
  baseURL: 'https://www.sczsk.top/fast-mail',
  // baseURL: 'https://8548v89n17.vicp.fun/fast-mail',
  // baseURL: 'http://www.sczsk.top:8023/fast-mail',
  //baseURL: process.env.VUE_APP_BASE_API_D, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000
  // request timeout
})

// request interceptor 请求拦截(请求发出前处理请求)
http.interceptors.request.use(
  config => {
    // do something before request is sent
    if (getToken()) {
      config.headers['token'] = getToken();
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor 响应拦截器（处理响应数据）
http.interceptors.response.use(
  response => {
    const res = response.data
    const result = res.success
    // 后端返回成功失败是用 success 的true 和 false 判断，所以在这里就获取 response.data 的 success
    // console.log(response.data.code,234)
    if (result) {
      return res
    } else {
      Message({
        message: res.message || 'error',
        type: 'error',
        duration: 5 * 1000
      })
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default http
