import request from '@/utils/request'

//会员列表
export function reqMemberList(pageSize,pageNo,phone){
    return request({
        url:`/win/user/userPage/${pageSize}/${pageNo}`,
        method:'POST',
        params:{
            phone
        }
    })
}

//用户 冻结/启用
export function reqFreeze(userId){
    return request({
        url:`/win/user/freeze`,
        method:'POST',
        params:{
            userId
        }
    })
}

//用户 启用
export function reqUpFreeze(userId){
    return request({
        url:`/win/user/unFreeze`,
        method:'POST',
        params:{
            userId
        }
    })
}