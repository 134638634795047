<template>
  <div class="user-accounts">
    <el-card>
      <div class="mange">
          <el-input style="width:200px;margin-right: 10px;" clearable v-model="input" placeholder="请输入姓名"></el-input>
          <el-button type="primary" @click="inputChange">查询</el-button>
      </div>
      <div class="common-tatbel">
          <table-Data
              :tableCrud="{index:'骑手结算'}"
              :tableData="tableData"
              :config="config"
              @handleAgree="handleAgree">
          </table-Data>
          <div class="block">
            <Pagination ref="pagination"
              :pageConfig="pageConfig"
              @handleCurrentChange="handleCurrentChange">
            </Pagination>
          </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination/Pagination';
import { numFilter } from '@/const/comm'
import tableData from '@/components/TableData/tableData'
import { MessageBox, Message } from 'element-ui';
import { reqUserAccountsList,reqUserAgree} from '@/api/accounts'
export default {
    components:{
      tableData,
      Pagination
    },
    data(){
      return{
        input:'',
        pageConfig: {
          //当前页数
          currentPage: 1,
          //每页显示数
          pageSize: 10,
          //总数据
          total: 10,
        },
        tableData:[],
        config:[
          {
            prop:'fullName',
            label:'骑手姓名'
          },
          {
            prop:'phone',
            label:'手机号'
          },
          // {
          //   prop:'courier.postName',
          //   label:'所属驿站'
          // },
          // {
          //   prop:'aliAccount',
          //   label:'支付宝账号'
          // },
          {
            prop:'withdrawCash',
            label:'出款金额'
          },
          {
            prop:'withdrawStatus',
            label:'出款状态'
          },
          {
            prop:'createTime',
            label:'申请时间'
          }
        ],
      }
    },
    computed:{

    },
    mounted(){
      this.getUserAccountsList(this.pageConfig.currentPage,this.pageConfig.pageSize,sessionStorage.getItem('postId'))
    },
    methods:{
      //查询 骑手申请提现列表
      getUserAccountsList(pageSize,pageNo,sysUserId,fullName){
        reqUserAccountsList(pageSize,pageNo,sysUserId,fullName).then(res=>{
          console.log(res,'骑手申请提现列表')
          res.data.page.list.forEach(item=>item.withdrawCash = numFilter(item.withdrawCash))
          this.pageConfig.currentPage = res.data.page.currPage
          this.pageConfig.total = res.data.page.total
          this.tableData = res.data.page.list
        })
      },
      //模糊查询
      inputChange(){
        this.getUserAccountsList(1,this.pageConfig.pageSize,sessionStorage.getItem('postId'),this.input)
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.getUserAccountsList(val,this.pageConfig.pageSize,sessionStorage.getItem('postId'))
      },
      //同意,拒绝
      handleAgree(data,index){
        console.log(data)
        let obj = {
          aliAccount:data.aliAccount,
          withdrawCash:data.withdrawCash,
          withdrawId:data.withdrawId,
          withdrawStatus:index,
          courierId:data.courierId,
          courier:data.courier,
          fullName:data.courier.fullName
        }
        if(['1','2'].includes(data.withdrawStatus)){
          Message({
            type:'info',
            message:'当前订单已确定，请勿重复操作！'
          })
        }else{
          if(index==1){
            MessageBox.confirm('此操作将同意该请求,是否继续?','提示',{
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(()=>{
              reqUserAgree(obj).then(res=>{
                if(res.code==200){
                  Message({
                    type:'success',
                    message:'同意成功'
                  })
                  this.getUserAccountsList(this.pageConfig.currentPage,this.pageConfig.pageSize)
                }
              })
            })
          }else{
            MessageBox.prompt('此操作将拒绝该请求,是否继续?','提示',{
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              inputPattern:/^(?=.*?[\u4E00-\u9FA5])[\d\u4E00-\u9FA5]{2,100}/,
              inputType:'textarea',
              inputErrorMessage: '请输入至少5个字符,不能全为数字且不包含英文字母',
              type: 'warning'
            }).then(({value})=>{
                obj.reason = value
                obj.courierId = data.courierId
                obj.withdrawCash = data.withdrawCash
                reqUserAgree(obj).then(res=>{
                  if(res.code==200){
                    Message({
                      type:'success',
                      message:'拒绝成功'
                    })
                    this.getUserAccountsList(this.pageConfig.currentPage,this.pageConfig.pageSize)
                }
              })
            })
          }
        }
      },
    }
}
</script>

<style lang="less" scoped>
.user-accounts{
  margin-left: 10px;
  .mange{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
  .block{
      display: flex;
      justify-content: flex-end;
    }
}

</style>
