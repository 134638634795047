import Mock from 'mockjs'

let List = []
let data = ['待取件','已揽件','已取件','退回件']
let thisIndex = Math.floor((Math.random() * data.length));
const count = 10
for (let i = 0; i < count; i++) {
  List.push(
    Mock.mock({
      id: Mock.Random.guid(),
      orderNum:Mock.Random.float(1000000000, 8000000000, 0, 0),
      flieName: Mock.Random.cname(),
      mailName: Mock.Random.cname(),
      canvassName: Mock.Random.cname(),
      dispatchName: Mock.Random.cname(),
      stage: Mock.Random.csentence(5,6),
      statu: data[thisIndex],
    })
  )
}
Mock.mock('/data/index','get',List)