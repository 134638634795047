import request from '@/utils/request'

//订单列表
export function reqOrderList(pageSize,pageNo,sysUserId,orderId){
    return request({
        url:`/win/order/orderPage/${pageSize}/${pageNo}?sysUserId=${sysUserId}&orderId=${orderId}`,
        method:'POST',
        // params:{
        //     orderId
        // }
    })
}
//订单列表
export function reqOrderSearch(status,amount,payStatus,orderId,pageSize,pageNum){
    return request({
        url:`/win/order/SelectStatus/${pageSize}/${pageNum}`,
        method:'GET',
        params:{
            status,
            amount,
            payStatus,
            orderId
        }
    })
}
//订单删除
export function reqOrderDel(orderId){
    return request({
        url:`/win/order/isDelete`,
        method:'GEt',
        params:{
            orderId
        }
    })
}
//订单同意
export function OrderAgree(courierId,orderDetailsId){
    return request({
        url:`/wechat/native/${orderDetailsId}`,
        method:'POST',
        params:{
            courierId
        }
    })
}