export default{
  state:{
    stateToken:'',
    userAdmin:{}
  },
  getters:{

  },
  mutations:{
    setToken(state,obj){
      state.userAdmin = obj
    },
    userAdmin(state,obj){
      state.userAdmin = obj
    }
  },
  actions:{

  }
}