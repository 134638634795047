<template>
  <div class="order">
    <el-drawer title="详细内容" :visible.sync="drawer" size="60%">
      <div class="drawer_top">
        <div class="drawer_span">收件人信息</div>
        <table-Data ref="table" :tableCrud="{ index: '无' }" :tableData="addressee" :config="configAddressee"
          @Agree="Agree">
        </table-Data>
      </div>
      <div class="drawer_top">
        <div class="drawer_span">接单人信息</div>
        <table-Data ref="table" :tableCrud="{ index: '有' }" :tableData="receiver" :config="configReceiver">
        </table-Data>
      </div>
    </el-drawer>
    <el-card>
      <div class="mange">
        <div class="mange-input">
          <el-input style="width:200px;margin-right: 10px;" clearable v-model="orderNo"
            placeholder="请输入订单编号"></el-input>
          <el-button type="primary" @click="inputChange">查询</el-button>
          <el-button @click="canlaChange">重置</el-button>
        </div>
      </div>
      <div class="common-tatbel">
        <table-Data ref="table" :tableCrud="{ index: '订单' }" :tableData="tableData" :config="config"
          @handleDelete="handleDelete" @handleDetailed="handleDetailed">
        </table-Data>
        <div class="block">
          <Pagination ref="pagination" :pageConfig="pageConfig" @handleCurrentChange="handleCurrentChange">
          </Pagination>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination/Pagination';
import SearchData from '@/components/SearchData/SearchData';
import { config, configAddressee, configReceiver, optionStatus } from '@/const/order/order'
import tableData from '@/components/TableData/tableData.vue'
import { numFilter } from '@/const/comm'
import { MessageBox, Message } from 'element-ui';
import { reqOrderList, reqOrderDel, reqOrderSearch, OrderAgree } from '@/api/order'
export default {
  components: {
    tableData,
    SearchData,
    Pagination
  },
  data() {
    return {
      drawer: false,
      total: 0,
      input: '',
      dialogVisible: false,
      handleIndex: 0,//表示新增的弹窗 0为新增1为编辑
      index: -1,
      pageConfig: {
        //当前页数
        currentPage: 1,
        //每页显示数
        pageSize: 10,
        //总数据
        total: 10,
      },
      tableData: [],
      addressee: [],
      receiver: [],
      config: config,
      configAddressee: configAddressee,
      configReceiver: configReceiver,
      optionStatus: optionStatus,
      orderNo: ''
    }
  },
  mounted() {
    this.getOrderList(this.pageConfig.pageSize, 1, sessionStorage.getItem('postId'), '')
  },
  methods: {
    //查询 骑手列表
    getOrderList(pageSize, pageNo, sysUserId, orderId) {
      reqOrderList(pageSize, pageNo, sysUserId, orderId).then(res => {
        console.log(res, '订单列表')
        if (res.data.page.data) {
          res.data.page.data.forEach(item => {
            item.totalPrice = numFilter(item.totalPrice)
            if (!item.sender && item.orderDetails) item.sender = item.orderDetails[0].consignee
          })
        }
        this.pageConfig.currentPage = res.data.page.currPage
        this.pageConfig.total = res.data.page.total
        this.tableData = res.data.page.data
      })
    },
    //模糊查询
    inputChange() {
      this.getOrderList(this.pageConfig.pageSize, 1, sessionStorage.getItem('postId'), this.orderNo)
    },
    //重置
    canlaChange() {
      this.orderNo = ''
      this.getOrderList(this.pageConfig.pageSize, 1, sessionStorage.getItem('postId'), '')
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    //分页
    handleCurrentChange(val) {
      let search = this.$refs.search.form
      if (search.searchOrder != '' || search.searchStatus != '' || search.searchPay != '' || search.searchInput != '') {
        reqOrderSearch(search.searchOrder, search.searchStatus, search.searchPay, search.searchInput, 10, val).then(res => {
          res.data.page.list.forEach(item => item.totalPrice = numFilter(item.totalPrice))
          this.tableData = res.data.page.list
          this.pageConfig.currentPage = res.data.page.pageNum
          this.pageConfig.total = res.data.page.total
        })
      } else {
        this.getOrderList(this.pageConfig.pageSize, val, sessionStorage.getItem('postId'), '')
      }
    },
    //删除按钮
    handleDelete(item) {
      reqOrderDel(item.orderId).then(res => {
        if (res.code == 200) {
          Message({
            type: 'success',
            message: '删除成功!'
          })
          this.getOrderList(this.pageConfig.currentPage, this.pageConfig.pageSize, sessionStorage.getItem('postId'))
        }
      })
    },
    // 同意按钮
    Agree(row) {
      // console.log(row)
      // console.log(this.receiver)

      OrderAgree(this.receiver[0].courierId, row.orderDetailsId)
        .then(res => {
          if (res.code == 200) {
            Message({
              type: 'success',
              message: '操作成功!'
            })
            this.drawer = false
            this.getOrderList(this.pageConfig.currentPage, this.pageConfig.pageSize, sessionStorage.getItem('postId'))
          }
        })
    },
    //详细内容
    handleDetailed(row) {
      console.log(row)
      this.drawer = true
      // if (row.courierDetails) {
      //   row.courierDetails.forEach(item => {
      //     item.receiverTime = row.createTime,
      //       item.completedTime = row.updateTime
      //   })
      // }
      this.addressee = row.orderDetails
      this.receiver = row.courierDetails
    }
  }
}
</script>

<style lang="less" scoped>
.el-table__body-wrapper {
  height: 90% !important;
  ;
}

.order {
  display: flex;
  flex-direction: column;
  margin-left: 10px;

  .drawer_top {
    padding: 20px;

    .drawer_span {
      margin-bottom: 10px;
    }
  }

  .common-tatbel {
    position: relative;
    height: 90%;

    .el-table__body-wrapper {
      height: 90%;
    }

    .block {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
    }
  }

  .mange {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
}

.form_item {
  display: flex;
}
</style>
