import request from '@/utils/request'

//后台--查询所有骑手数量和今日新增
export function reqAllCourier(){
    return request({
        url:`/win/courier/allCourier`,
        method:'POST',
    })
}

//后台--查询12个月驿站数量
export function reqMonthCourier(){
    return request({
        url:`/win/post/monthCourier`,
        method:'POST',
    })
}

//后台--查询12个月骑手数量
export function reqMonthUser(){
    return request({
        url:`/win/courier/monthCourier`,
        method:'POST',
    })
}

//后台--查询12个月订单数量及每月营业额
export function reqMonthOrder(){
    return request({
        url:`/win/order/monthOrder`,
        method:'POST',
    })
}

//后台--查询总订单营业额
export function reqOrderTotalMoney(){
    return request({
        url:`/win/order/orderTotalMoney`,
        method:'POST',
    })
}

//后台--查询每个月的用户数量
export function reqMonthMender(){
    return request({
        url:`/win/user/monthUser`,
        method:'POST',
    })
}