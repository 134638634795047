import request from '@/utils/request'


export function reqProFitRatioList(){
    return request({
        url:`/fm/profitratio/list`,
        method:'GET'
    })
}

//设置利润-服务配置修改
export function reqProFitRatioUpDate(obj){
    return request({
        url:`/fm/profitratio/insertProFitRatio`,
        method:'POST',
        data:obj
    })
}

