<template>
  <div class="stage">
    <el-card>
      <Stage-Form ref="stageForm"
        @subDefine="subDefine"
        @isStageAdd="isStageAdd">
      </Stage-Form>
      <!-- <div class="mange">
          <el-button type="primary" @click="stageAdd">
            + 新增
          </el-button>
          <div class="mange-form">
             <Search-Data ref="search"
             :SearchCrud="{obj:'驿站'}" 
             :optionStatus="optionStatus" 
             @inputChange="inputChange">
            </Search-Data>
          </div>
      </div> -->
      <div class="common-tatbel">
          <table-Data
              :tableCrud="{index:'驿站'}"
              :tableData="tableData"
              :config="config"
              @handleEdit="handleEdit"
              @handleAgree="handleAgree">
          </table-Data>
        <div class="block">
            <Pagination ref="pagination" 
              :pageConfig="pageConfig" 
              @handleCurrentChange="handleCurrentChange">
            </Pagination>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination/Pagination';
import SearchData from '@/components/SearchData/SearchData';
import { getArea} from '@/const/comm'
import tableData from '@/components/TableData/tableData'
import { MessageBox, Message } from 'element-ui';
import { reqStageList,reqStageRefuse,reqAddress,reqStageAdd,reqStageUpdate,reqStageSearch } from '@/api/stage';
import StageForm from "@/views/stage/StageForm";
export default {
  components:{
    tableData,
    StageForm,
    SearchData,
    Pagination
  },
  data(){
    return{
      stageIndex:0,
      input:'',
      index:-1,
      tableData:[],//驿站列表
      config:[
        {
          prop:'postId',
          label:'驿站Id'
        },
        {
          prop:'postName',
          label:'驿站名称'
        },
        {
          prop:'stageAddress',
          label:'驿站地址'
        },
        {
          prop:'longitudeAndLatitude',
          label:'驿站经纬度'
        },
        // {
        //   prop:'owner',
        //   label:'负责人'
        // },
        {
          prop:'postPhone',
          label:'手机号'
        },
        // {
        //   prop:'aliAccount',
        //   label:'支付宝账号'
        // },
        {
          prop:'postStatus',
          label:'驿站状态'
        },
        {
          prop:'createTime',
          label:'加盟时间'
        },
      ],
      pageConfig: {
        //当前页数
        currentPage: 1,
        //每页显示数
        pageSize: 10,
        //总数据
        total: 10,
      },
      optionStatus:[{value:'0',label:'申请中'},{value:'1',label:'已通过'},{value:'2',label:'已拒绝'}]
    }
  },
  watch:{
    // options(newVal) {
    //   this.keyValue++ //只要监听到数据源发生变化 ，改变keyValue的值，达到重新渲染的效果
    // }
  },
  mounted(){
    this.getStageList(this.pageConfig.currentPage,this.pageConfig.pageSize)
  },
  computed:{

  },
  methods:{
     //查询 驿站列表
    getStageList(pageSize,pageNo,postPhone){
      reqStageList(pageSize,pageNo,postPhone).then(res=>{
        console.log(res,'驿站列表')
        res.data.page.list.forEach(item=>{
          if(item.postAddress==null){
            item.postAddress=''
          }
        })
        this.pageConfig.currentPage = res.data.page.currPage
        this.pageConfig.total = res.data.page.total
        this.tableData = res.data.page.list
      })
    },
    //模糊查询
    inputChange(){
      let search = this.$refs.search.form
      reqStageSearch(search.searchStatus,search.searchInput,10,1).then(res=>{
        this.tableData = res.data.page.list
        this.pageConfig.currentPage = res.data.page.pageNum
        this.pageConfig.total = res.data.page.total
      })
    },
    handleCurrentChange(val) {
      if(this.$refs.search.form.searchInput!=''||this.$refs.search.form.searchStatus!=''){
        reqStageSearch(this.$refs.search.form.searchStatus,this.$refs.search.form.searchInput,10,val).then(res=>{
          this.tableData = res.data.page.list
          this.pageConfig.currentPage = res.data.page.pageNum
          this.pageConfig.total = res.data.page.total
        })
      }else{
        this.getStageList(val,this.pageConfig.pageSize,sessionStorage.getItem('postId'))
      }
    },
    initData () {

    },
    //新增按钮
    stageAdd(){
      this.stageIndex = 0
      this.$refs.stageForm.form={};
      this.$refs.stageForm.formMap=[];
      this.$refs.stageForm.dialogVisible = true;
    },
    //新增取消按钮
    isStageAdd(){
      this.$refs.stageForm.dialogVisible = false
    },
      //弹窗关闭的时候resetFields()
    //确定按钮
    subDefine(){
      let stageForms = this.$refs.stageForm.form
      stageForms.longitude = this.$refs.stageForm.formMap[0]
      stageForms.latitude = this.$refs.stageForm.formMap[1]
      let str = getArea(this.$refs.stageForm.formMap[2])
      stageForms.province = str.province
      stageForms.city = str.city
      stageForms.country = str.country
      stageForms.postAddress = str.postAddress
      this.$refs.stageForm.$refs.form.validate((validate)=>{
        if(validate){
          if(this.stageIndex===0){
            reqStageAdd(this.$refs.stageForm.form).then(res=>{
              console.log(res,'新增成功')
              if(res.code==200){
                Message({
                  type:'success',
                  message:'新增成功'
                })
                this.getStageList(this.pageConfig.currentPage,this.pageConfig.pageSize,)
                this.$refs.stageForm.dialogVisible = false
              }
            })
          }else{
            this.$refs.stageForm.form.postId = this.index
            reqStageUpdate(this.$refs.stageForm.form).then(res=>{
              console.log(res,'修改成功')
              if(res.code==200){
                Message({
                  type:'success',
                  message:'修改成功'
                })
                this.getStageList(this.pageConfig.currentPage,this.pageConfig.pageSize,)
                this.$refs.stageForm.dialogVisible = false
              }
            })
          }
        }
      })
    },
    //编辑
    handleEdit(data,index){
      if(['0','2'].includes(data.postStatus)){
        Message({
          type:'error',
          message:'该驿站异常'
        })
      }else{
        this.stageIndex = 1
        this.index = data.postId
        this.$refs.stageForm.dialogVisible = true
        this.$refs.stageForm.form = JSON.parse(JSON.stringify(data))
        this.$refs.stageForm.formMap = [data.longitude,data.latitude,data.province+data.city+data.country+data.postAddress]
      }
    },
    //同意
    handleAgree(data,index){
      console.log(data.postId,index)
      if(data.postStatus==1){
        Message({
          type:'error',
          message:'操作失败！'
        })
      }else if(data.postStatus==2){
        Message({
          type:'error',
          message:'操作失败！'
        })
      }else{
        if(index == 1){
           MessageBox.confirm('此操作将同意该请求,是否继续?','提示',{
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(()=>{
            this.reqStage(data.postId,index)
          })
        }else if(index == 2){
          MessageBox.confirm('此操作将拒绝该请求,是否继续?','提示',{
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(()=>{
            this.reqStage(data.postId,index)
          })
        }
       
      }
    },

    //接口
    reqStage(postId,index){
      reqStageRefuse(postId,index).then(res=>{
        if(res.code==200){
          Message({
            type: 'success',
            message: index==1?'同意成功!':'拒绝成功！'
          })
          this.getStageList(this.pageConfig.currentPage,this.pageConfig.pageSize)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.stage{
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  .mange{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .mange-form{
      width: 80%;
      display: flex;
      justify-content: flex-end;
    }
  }
  .block{
      display: flex;
      justify-content: flex-end;
    }
}
.form_item{
  display: flex;
}
</style>
