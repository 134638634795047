export default{
    state:{
        tabMenu:[
            {
                path: '/index',
                name: 'index',
                label: '首页',
                icon: 's-home',
                url: 'Index.vue'
            },
        ],
        delMenu:[
            {
                path: '/index',
                name: 'index',
                label: '首页',
                icon: 's-home',
                url: 'Index.vue'
            },
        ],
        isCollapse:false,
        headerMenu:{},
        exist:false
    },
    getters:{

    },
    mutations:{
        updateCollapse(state){
            state.isCollapse = !state.isCollapse
        },
        delCollapse(state){
            state.tabMenu = state.delMenu
        },
        //更新面包屑
        updateMenu(state,val){
          //判断添加的页面是否是首页
          if(val.name !=='index'){
              const index = state.tabMenu.findIndex(item => item.name ===val.name||item.label===val.label)
              //如果不存在
              if(index ===-1){
                  state.tabMenu.push(val)
              }
          }
        },
        headerMenu(state,obj){
          if(obj.name !=='index'){
            state.exist = true
            state.headerMenu = obj
          }else{
            state.exist = false
          }
        },
        //tag删除功能
        revmClose(state,item){
            console.log(item,'@');
            const index = state.tabMenu.findIndex(val=> val.name===item.name)
            state.tabMenu.splice(index,1)
        },
    }
}