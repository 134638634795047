<template>
  <div class="header_conn">
    <div class="left_nav">
      <!-- 面包屑 -->
      <el-button style="margin-right:20px"
                 icon="el-icon-s-fold"
                 size="mini"
                 @click="isCollapse"></el-button>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>
            <span style="cursor: pointer;">首页</span>
        </el-breadcrumb-item>
        <el-breadcrumb-item v-if="exist">
            <span style="cursor: pointer;">{{headerMenu.label}}</span>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="right_nav">
      <p class="rigth-name">您好,{{user.sysUserName}}</p>
      <el-dropdown :hide-on-click="false"
                   @command="delCommand">
        <span class="el-dropdown-link">
          <img src="../assets/image/椭圆形@2x.png" />
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="userInfo">个人中心</el-dropdown-item>
          <el-dropdown-item command="logout">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapState,mapMutations } from 'vuex';
import { removeToken} from '@/utils/auth'
export default {
  props: {
    user: {
      type: Object,
    }
  },
  data () {
    return {

    }
  },
  watch:{
    exist(newV){
      console.log(newV);
    }
  },
  computed: {
    ...mapState({
      tabMenu: state => state.tab.tabMenu,
      headerMenu: state => state.tab.headerMenu,
      exist: state => state.tab.exist
    }),
  },
  created(){
    console.log(this.$route.meta.label,'1');
    // this.tabMenu = this.$route.meta.label
  },
  methods: {
    ...mapMutations(['updateMenu']),
    isCollapse () {
      this.$store.commit('updateCollapse')
    },
    //退出
    delCommand (command) {
      console.log(command);
      if(command=='logout'){
        removeToken('token')
        sessionStorage.clear()
        this.$store.commit('delCollapse')
        this.$router.push('/login')
      }else if(command=='userInfo'){
        this.$router.push({name:'personal'})
        this.updateMenu({
          path:this.$route.path,
          label:this.$route.meta.label,
          name:'personal'
        })
      }
    }
  }
}
</script>

<style lang="less">
.header_conn {
  padding: 0 30px;
  background-color: #333;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left_nav {
    display: flex;
    align-items: center;
    .el-breadcrumb{
      .el-breadcrumb__item {
      .el-breadcrumb__inner {
        font-weight: normal;
        &.is-link {
          color: #97a8be;
        }
      }
      &:first-child {
        .el-breadcrumb__inner {
          color: #fff;
        }
      }
    }
    }
    
  }
  .right_nav {
    display: flex;
    align-items: center;
    .rigth-name {
      color: #ccc;
    }
    img {
      width: 40px;
      height: 40px;
      margin-left: 10px;
      border-radius: 50%;
    }
  }
}
</style>