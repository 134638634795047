export const config = [  
    {
      prop:'orderId',
      label:'订单号'
    },
    {
      prop:'sender',
      label:'收件人员'
    },
    {
      prop:'orderAddress',
      label:'寄件地址'
    },
    {
      prop:'phone',
      label:'手机号'
    },
    
    {
      prop:'totalPrice',
      label:'订单金额(￥)'
    },
    {
      prop:'createTime',
      label:'下单时间'
    },
  ]
export const configAddressee = [
  {
    prop:'orderId',
    label:'订单号'
  },
    {
      prop:'consignee',
      label:'收件人'
    },
    {
      prop:'consigneeAddress',
      label:'收件地址'
    },
    {
      prop:'phone',
      label:'手机号'
    },
    {
      prop:'goodsType',
      label:'物品类型'
    },
    {
      prop:'goodsWeight',
      label:'物品数量/kg'
    },
    {
      prop:'amount',
      label:'分账状态'
    },
    {
      prop:'payStatus',
      label:'支付状态'
    },
    {
      prop:'orderStatus',
      label:'订单状态'
    },
  ]
export const configReceiver = [
    {
      prop:'fullName',
      label:'接单人'
    },
    {
      prop:'postName',
      label:'服务驿站'
    },
    {
      prop:'phone',
      label:'手机号'
    },
    {
      prop:'createTime',
      label:'接单时间'
    },
    {
      prop:'closingTime',
      label:'完成时间'
    },
  ]
export const optionStatus = [
    {value:'0',label:'已下单'},
    {value:'1',label:'待取件'},
    {value:'2',label:'已取件'},
    {value:'3',label:'已取消'},
    {value:'4',label:'已入库'},
    {value:'5',label:'退件中'},
    {value:'6',label:'待送回'},
    {value:'7',label:'已送回'},
  ]