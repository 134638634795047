<template>
  <div class="tabMua">
    <el-tag v-for="(item,index) in tabMenu"
            :key="item.path"
            :closable="item.name!=='index'"
            :effect="$route.meta.label === item.label ? 'dark':'plain'"
            @click="changMenu(item)"
            @close="handMenu(item,index)">
      {{ item.label }}
    </el-tag>
  </div>
</template>
<script>
import { mapState, mapMutations,initialization } from 'vuex';
export default {
  data () {
    return {

    }
  },
  computed: {
    ...mapState({
      tabMenu: state => state.tab.tabMenu
    })
  },

  methods: {
    ...mapMutations(['revmClose','headerMenu']),
    //点击tag跳转功能
    changMenu (item) {
      console.log(item)
      this.$router.push({ name: item.name })
      this.headerMenu(item)
    },
    //点击tag删除功能
    handMenu (item, index) {

      this.revmClose(item)
      const length = this.tabMenu.length
      //删除之后的跳转逻辑
      if (item.name !== this.$route.name) {
        return;
      }
      //表示删除的最后一项
      if (index === length) {
        this.$router.push({
          name: this.tabMenu[index - 1].name
        })
        this.headerMenu(this.tabMenu[index-1])
      } else {
        this.$router.push({
          name: this.tabMenu[index].name
        })
        this.headerMenu(this.tabMenu[index])
      }
    }
  }
}
</script>

<style lang="less" scoped>
.tabMua {
  height: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
  // position: absolute;
  .el-tag {
    margin-right: 5px;
    cursor:pointer
  }
}
</style>