<template>
    <div class="user">
      <el-card>
      <el-dialog
        title="新增用户"
        :visible.sync="dialogVisible"
        width="30%">
        <el-form ref="form" :rules="rules" :model="form" label-width="100px">
            <el-form-item label="用户名:" prop="sysUserName" style="width:400px">
              <el-input placeholder="请输入姓名" v-model="form.sysUserName"></el-input>
            </el-form-item>
            <el-form-item label="密码:" prop="sysPassword" style="width:400px">
              <el-input autocomplete="off" placeholder="请输入密码" v-model="form.sysPassword"></el-input>
            </el-form-item>
            <el-form-item label="权限:" prop="isAdmin" style="width:400px">
              <el-select v-model="form.isAdmin" placeholder="请选择">
                <el-option label="超级管理员" value="1"></el-option>
                <el-option label="普通管理员" value="0"></el-option>
              </el-select>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="isUserAdd">取 消</el-button>
          <el-button type="primary" @click="subimt">确 定</el-button>
        </span>
      </el-dialog>
      <div class="mange">
          <el-button type="primary" @click="userAdd">
            + 新增
          </el-button>
          <div>
            <el-input style="width:200px;margin-right: 10px;" clearable v-model="input" placeholder="请输入用户名"></el-input>
            <el-button type="primary" @click="inputChange">查询</el-button>
          </div>
      </div>
      <div class="common-tatbel">
          <table-Data 
            :tableData="tableData" 
            :config="config"
            @handleEdit="handleEdit"
            @handleDelete=handleDelete>
            
          </table-Data>
          <div class="block">
            <Pagination ref="pagination" 
              :pageConfig="pageConfig" 
              @handleCurrentChange="handleCurrentChange">
            </Pagination>
          </div>
      </div>
    </el-card>
    </div>
  </template>
  
  <script>
  import Pagination from '@/components/Pagination/Pagination';
  import tableData from '@/components/TableData/tableData'
  import { MessageBox, Message } from 'element-ui';
  import { reqAdminList,reqAdminListAdd,reqAdminListDel,reqAdminListUpDate,reqAdminToken} from '@/api/login'
  export default {
    components:{
      tableData,
      Pagination
    },
    data(){
      return{
        input:'',
        dialogVisible:false,
        userIndex:0,
        index:-1,
        userId:'',//当前登录的用户的级别
        sysUserId:'',//当前登录的用户的id
        form:{
          sysUserName:'',
          sysPassword:'',
          isAdmin:''
        },
        tableData:[],
        config:[
          {
            prop:'sysUserId',
            label:'管理员Id'
          },
          {
            prop:'sysUserName',
            label:'用户名'
          },{
            prop:'sysPassword',
            label:'密码'
          }
          ,{
            prop:'isAdmin',
            label:'权限级别'
          }
          ,{
            prop:'createTime',
            label:'创建时间'
          }
        ],
        pageConfig: {
          //当前页数
          currentPage: 1,
          //每页显示数
          pageSize: 10,
          //总数据
          total: 10,
        },
        rules: {
            sysUserName: [
                { required: true, message: '请输入用户名' }
            ],
            sysPassword: [
                { required: true, message: '请输入密码' }
            ],
            isAdmin: [
                { required: true, message: '请选择权限级别'}
            ],
          
        },
      }
    },
    mounted(){
      this.getToken()
      this.getUserList(this.pageConfig.currentPage,this.pageConfig.pageSize,sessionStorage.getItem('postId'))
    },
    computed:{
    
    },
    watch:{
      
    },
    methods:{
      //获取登录的用户
      getToken(){
        reqAdminToken().then(res=>{
          this.userId = res.data.user.isAdmin
          this.sysUserId = res.data.user.sysUserId
        })
        
      },
      //查询 骑手列表
      getUserList(pageSize,pageNo,sysUserId,sysUserName){
        reqAdminList(pageSize,pageNo,sysUserId,sysUserName).then(res=>{
          console.log(res,'reqAdminList')
          this.pageConfig.currentPage = res.data.page.currPage
          this.pageConfig.total = res.data.page.total
          this.tableData = res.data.page.list
        })
      },
      //模糊查询 
      inputChange(){
        this.getUserList(1,this.pageConfig.pageSize,this.input)
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
      },
      //分页
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.getUserList(val,this.pageConfig.pageSize)
      },
      //新增按钮
      userAdd(){
        if(this.userId=='1'){
          this.userIndex = 0
          this.form ={}
          this.dialogVisible = true
          this.resetForm()
        }else{
          Message({
            type:'error',
            message:'你不是超级管理员！权限不够'
          })
        }
      },
      //新增取消按钮
      isUserAdd(){
        this.dialogVisible = false
      },
      //确定按钮
      subimt(){
        if(this.userIndex===0){
          this.$refs.form.validate((validate)=>{
            if(validate){
              console.log(this.form)
              reqAdminListAdd(this.form).then(res=>{
                if(res.code==200){
                    Message({
                        type:'success',
                        message:'添加成功'
                    })
                    this.getUserList(1,this.pageConfig.pageSize)
                    this.dialogVisible = false
                }
                
              })
            }
          })
        }else{
          this.$refs.form.validate((validate)=>{
            if(validate){
              console.log(this.form)
              this.form.sysUserId = this.index
              reqAdminListUpDate(this.form).then(res=>{
                if(res.code==200){
                    Message({
                        type:'success',
                        message:'修改成功'
                    })
                    this.getUserList(1,this.pageConfig.pageSize)
                    this.dialogVisible = false
                }
                
              })
            }
          })
        }
      },
      //编辑按钮
      handleEdit(data,index){
        if(this.userId=='1'){
          this.userIndex = 1
          this.dialogVisible = true
          this.index = data.sysUserId
          this.form =JSON.parse(JSON.stringify(data))
          this.form.sysPassword = '******'
        }else{
          Message({
            type:'error',
            message:'你不是超级管理员！权限不够'
          })
        }
      },
      //  //弹窗关闭的时候resetFields()
      resetForm() {
        if (typeof this.$refs.form !== "undefined") {
            this.$refs.form.resetFields();
        }
      },
      //删除
      handleDelete(data){
        if(this.userId=='1'){
          MessageBox.confirm('此操作将删除该账号！是否继续？','提示',{
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(()=>{
            reqAdminListDel(data.sysUserId,this.sysUserId).then(res=>{
              if(res){
                if(res.code==200){
                  Message({
                    type:'success',
                    message:'删除成功'
                  })
                  this.getUserList(this.pageConfig.currentPage,this.pageConfig.pageSize)
                }
              }
            })
          })
        }else{
          Message({
            type:'error',
            message:'你不是超级管理员！权限不够'
          })
        }
        
      },
      //转换时间方法
      getDate(){
        //date是传过来的时间戳，注意需为13位，10位需*1000
        //也可以不传,获取的就是当前时间
          let time = new Date();
          let year= time.getFullYear()  //年
          let month = ("0" + (time.getMonth() + 1)).slice(-2); //月
          let day = ("0" + time.getDate()).slice(-2); //日
          let mydate = year + "-" + month + "-" + day;
          return mydate
      }
    }
  }
  </script>
  
  <style lang="less" scoped>
  .user{
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    .mange{
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      margin-bottom: 10px;
    }
    .block{
        display: flex;
        justify-content: flex-end;
      }
  }
  </style>