import request from '@/utils/request'

//驿站列表
export function reqStageList(pageSize,pageNo,postPhone){
    return request({
        url:`/win/post/postPage/${pageSize}/${pageNo}`,
        method:'POST',
        params:{
            postPhone
        }
    })
}

//同意,拒绝
export function reqStageRefuse(postId,type){
    return request({
        url:`/win/post/pass`,
        method:'POST',
        params:{
            postId,
            type
        }
    })
}

//省市区
export function reqAddress(){
    return request({
        url:`/fm/city/findCity`,
        method:'GET',
    })
}

//驿站新增
export function reqStageAdd(obj){
    return request({
        url:`/win/post/addPost`,
        method:'POST',
        data:obj
    })
}

//驿站编辑
export function reqStageUpdate(obj){
    return request({
        url:`/win/post/updatePost`,
        method:'POST',
        data:obj
    })
}

//骑手申请驿站列表
export function reqStageInfo(pageSize,pageNo,sysUserId,name){
    return request({
        url:`/win/post/findAll/${pageSize}/${pageNo}?sysUserId=${sysUserId}`,
        method:'POST',
        params:{
            name
        }
    })
}

//同意,拒绝 骑手申请驿站
export function reqStageInfoRefuse(courierPostId,type){
    return request({
        url:`/win/post/result`,
        method:'POST',
        params:{
            courierPostId,
            type
        }
    })
}

//驿站模糊查询
export function reqStageSearch(postStatus,postName,pageNum,pageSize){
    return request({
        url:`/fm/post/selectPostStatus/${pageNum}/${pageSize}`,
        method:'GET',
        params:{
            postStatus,
            postName
        }
    })
}