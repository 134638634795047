<template>
  <div>
    <el-table :data="tableData" border style="width: 100%">
      <template v-for="item in config">
        <template v-if="item.prop == 'avatar'">
          <el-table-column :label="item.label" align="center">
            <template slot-scope="scope">
              <img class="image" :src="scope.row.avatar" />
            </template>
          </el-table-column>
        </template>
        <template v-else-if="item.prop == 'isFreeze'">
          <el-table-column :label="item.label" align="center">
            <template slot-scope="scope">
              <el-tag :type="scope.row.isFreeze == 0 ? 'success' : 'danger'">
                {{ scope.row.isFreeze == 0 ? '正常' : '已冻结' }}
              </el-tag>
            </template>
          </el-table-column>
        </template>
        <template v-else-if="item.prop == 'amount'">
          <el-table-column :label="item.label" align="center">
            <template slot-scope="scope">
              <el-tag :type="scope.row.fenpayState == 1 ? 'success' : 'danger'">
                {{ scope.row.fenpayState == 1 ? '已分账' : '还未分账' }}
              </el-tag>
            </template>
          </el-table-column>
        </template>
        <template v-else-if="item.prop == 'payStatus'">
          <el-table-column :label="item.label" align="center">
            <template slot-scope="scope">
              <el-tag :type="scope.row.payState == 1 ? 'success' : 'danger'">
                {{ scope.row.payState == 0 ? '未支付' : '已支付' }}
              </el-tag>
            </template>
          </el-table-column>
        </template>
        <template v-else-if="item.prop == 'postStatus'">
          <el-table-column :label="item.label" align="center">
            <template slot-scope="scope">
              <el-tag :type="scope.row.postStatus == 0 ? 'danger' : scope.row.postStatus == 1 ? '' : 'success'">
                {{ scope.row.postStatus == 0 ? '申请中' : scope.row.postStatus == 1 ? '已通过' : '已拒绝' }}
              </el-tag>
            </template>
          </el-table-column>
        </template>
        <template v-else-if="item.prop == 'stageAddress'">
          <el-table-column :label="item.label" align="center">
            <template slot-scope="scope">
              {{ scope.row.province }}{{ scope.row.city == scope.row.province ? '' : scope.row.city }}{{
      scope.row.country }}{{ scope.row.postAddress }}
            </template>
          </el-table-column>
        </template>
        <template v-else-if="item.prop == 'status'">
          <el-table-column :label="item.label" align="center">
            <template slot-scope="scope">
              <el-tag :type="scope.row.status == 0 ? 'danger' : scope.row.status == 1 ? '' : 'success'">
                {{ scope.row.status == 0 ? '申请中' : scope.row.status == 1 ? '已同意' : '已拒绝' }}
              </el-tag>
            </template>
          </el-table-column>
        </template>
        <template v-else-if="item.prop == 'withdrawStatus'">
          <el-table-column :label="item.label" align="center">
            <template slot-scope="scope">
              <el-tooltip placement="top" :disabled="scope.row.withdrawStatus < 2" effect="dark"
                :content="scope.row.reason">
                <el-tag
                  :type="scope.row.withdrawStatus == 0 ? 'danger' : scope.row.withdrawStatus == 1 ? '' : 'success'">
                  {{ scope.row.withdrawStatus == 0 ? '申请中' : scope.row.withdrawStatus == 1 ? '已通过' : '已拒绝' }}
                </el-tag>
              </el-tooltip>
            </template>
          </el-table-column>
        </template>
        <template v-else-if="item.prop == 'orderAddress'">
          <el-table-column :label="item.label" align="center">
            <template slot-scope="scope">
              {{ scope.row.province }}{{ scope.row.city == scope.row.province ? '' : scope.row.city }}{{
      scope.row.county }}{{ scope.row.address }}
            </template>
          </el-table-column>
        </template>
        <template v-else-if="item.prop == 'isAdmin'">
          <el-table-column :label="item.label" align="center">
            <template slot-scope="scope">
              {{ scope.row.isAdmin == 1 ? '超级管理员' : '普通管理员' }}
            </template>
          </el-table-column>
        </template>
        <template v-else-if="item.prop == 'sysPassword'">
          <el-table-column :label="item.label" align="center">
            <template slot-scope="scope">
              {{ scope.row.sysPassword ? '******' : '' }}
            </template>
          </el-table-column>
        </template>
        <template v-else-if="item.prop == 'orderStatus'">
          <el-table-column :label="item.label" align="center">
            <template slot-scope="scope">
              {{ scope.row.status | statusStr }}
            </template>
          </el-table-column>
        </template>
        <template v-else-if="item.prop == 'longitudeAndLatitude'">
          <el-table-column :label="item.label" align="center">
            <template slot-scope="scope">
              <div>{{ scope.row.longitude }}</div>
              <div>{{ scope.row.latitude }}</div>
            </template>
          </el-table-column>
        </template>
        <template v-else-if="item.prop == 'consigneeAddress'">
          <el-table-column :label="item.label" align="center">
            <template slot-scope="scope">
              {{ scope.row.province }}{{ scope.row.city == scope.row.province ? '' : scope.row.city }}{{
      scope.row.county }}{{ scope.row.address }}
            </template>
          </el-table-column>
        </template>
        <template v-else-if="item.prop == 'sfzNum'">
          <el-table-column :label="item.label" align="center">
            <template slot-scope="scope">
              {{ scope.row.post.province }}{{ scope.row.post.city == scope.row.post.province ? '' : scope.row.post.city
              }}{{ scope.row.post.country }}{{ scope.row.post.postAddress }}
            </template>
          </el-table-column>
        </template>
        <template v-else-if="item.prop == 'postName'">
          <el-table-column :label="item.label" align="center">
            <template slot-scope="scope">
              {{ scope.row.postName ? scope.row.postName : '暂无' }}
            </template>
          </el-table-column>
        </template>
        <template v-else-if="item.prop == 'province'">
          <el-table-column :label="item.label" align="center">
            <template slot-scope="scope">
              {{ scope.row.province ? scope.row.province : '暂无' }}
            </template>
          </el-table-column>
        </template>
        <template v-else-if="item.prop == 'city'">
          <el-table-column :label="item.label" align="center">
            <template slot-scope="scope">
              {{ scope.row.city ? scope.row.city : '暂无' }}
            </template>
          </el-table-column>
        </template>
        <template v-else-if="item.prop == 'country'">
          <el-table-column :label="item.label" align="center">
            <template slot-scope="scope">
              {{ scope.row.country ? scope.row.country : '暂无' }}
            </template>
          </el-table-column>
        </template>
        <template v-else-if="item.prop == 'fullName'">
          <el-table-column :label="item.label" align="center">
            <template slot-scope="scope">
              {{ scope.row.fullName ? scope.row.fullName : '暂无' }}
            </template>
          </el-table-column>
        </template>
        <template v-else>
          <el-table-column :prop="item.prop" :label="item.label + ''" align="center">
          </el-table-column>
        </template>
      </template>
      <template v-if="tableCrud.index == '会员' | tableCrud.index == '骑手'">
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="text" icon="el-icon-switch-button"
              @click="handleFreeze(scope.row)">冻结</el-button>
            <el-button type="text" size="mini" icon="el-icon-video-play"
              @click="handleIsFreeze(scope.row)">启用</el-button>
          </template>
        </el-table-column>
      </template>
      <template v-if="tableCrud.index == '无'">
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini" :disabled="scope.row.fenpayState == 0 ? false : true" type="text"
              icon="el-icon-success" @click="Agree(scope.row)">确认订单</el-button>
          </template>
        </el-table-column>
      </template>
      <template v-if="tableCrud.index == '订单' | tableCrud.index == '反馈'">
        <el-table-column label="操作" width="200" align="center">
          <template slot-scope="scope">
            <el-button v-if="tableCrud.index == '订单'" size="mini" type="text" icon="el-icon-document-copy"
              @click="handleDetailed(scope.row)">详细内容</el-button>
            <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </template>
      <template v-if="tableCrud.index == '驿站'">
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="text" icon="el-icon-success" @click="handleAgree(scope.row, 1)">同意</el-button>
            <!-- <el-button size="mini" icon="el-icon-edit" type="text" @click="handleEdit(scope.row,scope.$index)">编辑</el-button> -->
            <el-button type="text" size="mini" icon="el-icon-error" @click="handleAgree(scope.row, 2)">拒绝</el-button>
          </template>
        </el-table-column>
      </template>
      <template v-if="tableCrud.index == '服务' | tableCrud.index == '骑手结算'">
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="text" icon="el-icon-success" @click="handleAgree(scope.row, 1)">同意</el-button>
            <el-button type="text" size="mini" icon="el-icon-error" @click="handleAgree(scope.row, 2)">拒绝</el-button>
          </template>
        </el-table-column>
      </template>
      <template v-if="tableCrud.index == '驿站结算'">
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="text" @click="handleSusan(scope.row)">结算</el-button>
          </template>
        </el-table-column>
      </template>
      <template v-if="tableCrud.index == '利润' && quanxian == 1">
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="text" icon="el-icon-edit" @click="handleEdit(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </template>
      <template v-if="tableCrud.index == '0'">
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="text" icon="el-icon-edit" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </template>
    </el-table>
  </div>
</template>

<script>
import { MessageBox, Message } from 'element-ui';
export default {
  props: {
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },
    config: {
      type: Array,
      default() {
        return [];
      }
    },
    tableCrud: {
      type: Object,
      default() {
        return {
          index: '0'
        }
      }
    }
  },
  data() {
    return {
      drawer: false,
      quanxian: sessionStorage.getItem('postId'),
      screenHeight: 600
    }
  },
  filters: {
    statusStr(index) {
      return index == 0 ? '已下单' : index == 1 ? '待取件' : index == 2 ? '已取件' : index == 3 ? '已取消 ' : index == 4 ? '已入库' : index == 5 ? '退件中' : index == 6 ? '待送回' : '已送回'
    }
  },
  mounted() {
    this.updateScreenHeight();
    window.addEventListener('resize', this.updateScreenHeight);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateScreenHeight);
  },
  methods: {
    updateScreenHeight() {
      console.log(window.innerHeight, 'window.innerHeight')
      this.screenHeight = parseInt(window.innerHeight * 0.8);
      console.log(this.screenHeight, 'window.innerHeight111')
    },
    //添加按钮
    hanIncrease() {
      this.$emit('hanIncrease')
    },
    //编辑
    handleEdit(row) {
      // console.log("----------------")
      // console.log(row,'1111');
      this.$emit('handleEdit', row)
    },
    //删除
    handleDelete(row) {
      MessageBox.confirm("此操作将删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$emit("handleDelete", row);
      }).catch(() => {
        Message({
          type: "info",
          message: "操作已取消",
        });
      });
    },
    // 订单同意接口
    Agree(row) {
      this.$emit('Agree', row)
    },
    //冻结
    handleFreeze(row) {
      this.$emit('handleFreeze', row)
    },
    //启用
    handleIsFreeze(row) {
      this.$emit('handleIsFreeze', row)
    },
    handleAgree(row, index) {
      this.$emit('handleAgree', row, index)
    },
    //结算
    handleSusan(row) {
      this.$emit('handleSusan', row)
    },
    //详细内容
    handleDetailed(row) {
      this.$emit('handleDetailed', row)
    }
  }
}
</script>

<style lang="less" scoped>
.but {
  margin-bottom: 10px;
}

.image {
  width: 80px;
  height: 80px;
}
</style>