export function numFilter(value) {
    let tempVal = parseFloat(value).toFixed(3)
    let realVal = tempVal.substring(0, tempVal.length - 1)
    return realVal
}

export function getDate(){
    let time = new Date();
    let year= time.getFullYear()  //年
    let month = ("0" + (time.getMonth() + 1)).slice(-2); //月
    let day = ("0" + time.getDate()).slice(-2); //日
    let mydate = year + "-" + month + "-" + day;
    return mydate
}

export function getArea(str) {
    let area = {}
    let index11 = 0
    let index1 = str.indexOf("省")
    if (index1 == -1) {
        index11 = str.indexOf("自治区")
        if (index11 != -1) {
        area.province = str.substring(0, index11 + 3)
        } else {
        area.province = str.substring(0, 0)
        }
    } else {
        area.province = str.substring(0, index1 + 1)
    }

    let index2 = str.indexOf("市")
    if (index11 == -1) {
        area.city = str.substring(index11 + 1, index2 + 1)
    } else {
        if (index11 == 0) {
        area.city = str.substring(index1 + 1, index2 + 1)
        } else {
        area.city = str.substring(index11 + 3, index2 + 1)
        }
    }

    let index3 = str.lastIndexOf("区")
    if (index3 == -1) {
        index3 = str.indexOf("县")
        area.country = str.substring(index2 + 1, index3 + 1)
    } else {
        area.country = str.substring(index2 + 1, index3 + 1)
    }

    area.postAddress = str.split(area.country)[1]
    return area;
}

export function getTreeData(data){
    for(var i=0;i<data.length;i++){
        if(data[i].childCity.length<1){
        // children若为空数组，则将children设为undefined
        data[i].childCity=undefined;
        }else {
        // children若不为空数组，则继续 递归调用 本方法
        this.getTreeData(data[i].childCity);
        }
    }
    return data;
}