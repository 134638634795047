import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex);

import tab from './modules/tab'
import admin from './modules/admin'
export default new Vuex.Store({
	modules:{
		tab,
    admin
	}
})