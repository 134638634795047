<template>
  <div class="profit">
    <Profit-Form ref="fitForm" @isStageAdd="isStageAdd" @subDefine="subDefine">
    </Profit-Form>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>利润配置</span>
      </div>
      <div>
        <ul class="list-group">
          <li class="list-group-item">
            <div :class="`el-icon-user-solid`">骑手利润(%)</div>
            <div class="pull-right">
              <template v-if="profitIndex">
                {{ form.courierPercent }}%
              </template>
              <template v-else>
                <el-input class="input" :min="0" size="mini" v-model="form.courierPercent"></el-input>
              </template>
            </div>
          </li>
          <li class="list-group-item">
            <div :class="`el-icon-s-shop`">驿站利润(%)</div>
            <div class="pull-right">
              <template v-if="profitIndex">
                {{ form.postPercent }}%
              </template>
              <template v-else>
                <el-input class="input" :min="0" size="mini" v-model="form.postPercent"></el-input>
              </template>
            </div>
          </li>
          <li class="list-group-item">
            <div :class="`el-icon-s-data`">平台利润(%)</div>
            <div class="pull-right">
              <template v-if="profitIndex">
                {{ form.sysPercent }}%
              </template>
              <template v-else>
                <el-input class="input" :min="0" size="mini" v-model="form.sysPercent"></el-input>
              </template>
            </div>
          </li>
          <li class="list-group-item" v-if="quqnxian == 1">
            <div :class="`el-icon-s-claim`">修改利润</div>
            <div class="pull-right">
              <el-button type="primary" size="mini" @click="profitChange">
                <template v-if="profitIndex">
                  修改
                </template>
                <template v-else>
                  取消
                </template>
              </el-button>
              <template v-if="!profitIndex">
                <el-button type="primary" size="mini" @click="change">保存</el-button>
              </template>
            </div>
          </li>
        </ul>
      </div>
    </el-card>
    <el-card class="box-card-item">
      <div slot="header" class="clearfix">
        <span>服务配置</span>
      </div>
      <div class="el_calss">
        <table-Data :tableCrud="{ index: '利润' }" :tableData="tableData" :config="config" @handleEdit="handleEdit">
        </table-Data>
      </div>
    </el-card>
  </div>
</template>

<script>
import ProfitForm from './ProfitForm.vue';
import tableData from '@/components/TableData/tableData.vue'
import { numFilter } from '@/const/comm'
import { ProFitRatio, reqFind, reqFindUpdate } from '@/api/accounts'
import { reqProFitRatioList, reqProFitRatioUpDate } from '@/api/profitratio'
import { Message } from 'element-ui'
export default {
  components: {
    tableData,
    ProfitForm
  },
  data() {
    return {
      tableData: [],
      config: [
        {
          prop: 'province',
          label: '省份',
        },
        {
          prop: 'foundationMoney',
          label: '预付(元)',
        },
        {
          prop: 'firstWeight',
          label: '首重(kg/￥)',
        },
        {
          prop: 'continuousWeight',
          label: '续重(kg/￥)',
        }
      ],
      profitIndex: true,
      checkAll: false,
      checkedCities: [],
      cities: [],
      quqnxian: sessionStorage.getItem('postId'),
      isIndeterminate: true,
      form: {
        courierPercent: '0',
        postPercent: '0',
        sysPercent: '0',
        propId: '1'
      },
    }
  },
  mounted() {
    this.getFind()
    this.getAddress()

  },
  methods: {
    getAddress() {
      reqProFitRatioList().then(res => {
        res.data.list.forEach(item => {
          item.foundationMoney = numFilter(item.foundationMoney)
          item.firstWeight = numFilter(item.firstWeight)
          item.continuousWeight = numFilter(item.continuousWeight)
        })
        this.tableData = res.data.list
      })
    },
    getFind() {
      reqFind().then(res => {
        console.log(res.data.proportion, '11')
        this.form = res.data.proportion
        this.ProFitRatioA()
      })
    },

    profitChange() {
      this.profitIndex = !this.profitIndex
      this.getFind()

    },
    change() {
      let courierPercent = parseFloat(this.form.courierPercent)
      let postPercent = parseFloat(this.form.postPercent)
      let sysPercent = parseFloat(this.form.sysPercent)
      if (courierPercent + postPercent + sysPercent == 100.00) {
        reqFindUpdate(this.form).then(res => {
          if (res.code == 200) {
            Message({
              type: 'success',
              message: '修改成功'
            })
            this.profitIndex = true
            this.getFind()
          }
        })
      } else {
        Message({
          type: 'error',
          message: '比例错误,重新配置'
        })
      }
    },
    //全选
    handleCheckAllChange(val) {
      console.log(val);
      this.checkedCities = val ? this.cities : []
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
    },
    //编辑取消
    isStageAdd() {
      this.$refs.fitForm.dialogVisible = false
    },
    //编辑确定
    subDefine() {
      console.log('2');
      this.$refs.fitForm.$refs.form.validate((dialogVisible) => {
        if (dialogVisible) {
          //console.log(this.form)
          reqProFitRatioUpDate(this.$refs.fitForm.form).then(res => {
            //console.log(res)
            if (res.code === 200) {
              Message({
                type: 'success',
                message: '修改成功'
              })
              this.$refs.fitForm.dialogVisible = false
            }
          })
        }
      })
    },
    //编辑
    handleEdit(row) {
      console.log(row);
      this.$refs.fitForm.form = row
      this.$refs.fitForm.dialogVisible = true
    },
    ProFitRatioA() {
      let data = {
        courierPercent: this.form.courierPercent,
        postPercent: this.form.postPercent,
        sysPercent: this.form.sysPercent
      }
      ProFitRatio(data).then(res => {
        console.log(res)

      })
    }

  }
}
</script>

<style lang="less">
.el_calss {
  ::v-deep .el-card__body {
    height: 70vh !important;
    overflow-y: scroll !important;
  }
}

.input {
  width: 300px;
}

.profit {
  margin-left: 10px;
  display: flex;
}

.box-card {
  width: 50%;
  height: 600px;
  // height: 600px;
  margin-right: 10px;

  // overflow: hidden;
  // overflow-y: scroll;
  .list-group {
    display: flex;
    flex-direction: column;

    .list-group-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 40px;
      border-bottom: 1px solid #ccc;

    }
  }

  .transfer {
    display: flex;
    justify-content: center;
  }
}

.box-card-item {
  width: 50%;
}

.text_item {
  margin-left: 40px;
}
</style>
