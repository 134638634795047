<template>
  <div class="member">
    <el-card>
      <div class="mange">
          <el-input style="width:200px;margin-right: 10px;" clearable v-model="input" placeholder="请输入手机号"></el-input>
          <el-button type="primary" @click="inputChange">查询</el-button>
      </div>
      <div class="common-tatbel">
          <table-Data 
              :tableCrud="{index:'会员'}"
              :tableData="tableData" 
              :config="config"
              @handleFreeze="handleFreeze"
              @handleIsFreeze=handleIsFreeze>
          </table-Data>
          <div class="block">
            <Pagination ref="pagination" 
              :pageConfig="pageConfig" 
              @handleCurrentChange="handleCurrentChange">
            </Pagination>
          </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination/Pagination';
import tableData from '@/components/TableData/tableData'
import { mapState } from 'vuex';
import { MessageBox, Message } from 'element-ui';
import { reqMemberList,reqFreeze,reqUpFreeze} from '@/api/member';
export default {
    components:{
      tableData,
      Pagination
    },
    data(){
        return{
            input:'',
            pageConfig: {
              //当前页数
              currentPage: 1,
              //每页显示数
              pageSize: 10,
              //总数据
              total: 10,
            },
            tableData:[],
            config:[
              {
                prop:'userId',
                label:'用户Id'
              },
              {
                prop:'nickName',
                label:'昵称'
              },
              {
                prop:'avatar',
                label:'头像'
              },
              {
                prop:'sex',
                label:'性别'
              },
              {
                prop:'phone',
                label:'手机号'
              },
              {
                prop:'userNum',
                label:'身份证号'
              },
              {
                prop:'isFreeze',
                label:'状态'
              },
              {
                prop:'createTime',
                label:'创建时间'
              },
            ]
        }
    },
    computed:{
        // ...mapState({
        //     tableData:state =>state.member.tableData
        // })
    },
    mounted(){
      this.getMemberList(this.pageConfig.currentPage,this.pageConfig.pageSize)
    },
    methods:{
       //查询 骑手列表
        getMemberList(pageSize,pageNo,phone){
          reqMemberList(pageSize,pageNo,phone).then(res=>{
            console.log(res,'会员列表')
            this.pageConfig.currentPage = res.data.page.currPage
            this.pageConfig.total = res.data.page.total
            this.tableData = res.data.page.list
          })
        },
        //模糊查询 
        inputChange(){
          this.getMemberList(1,this.pageConfig.pageSize,this.input)
        },
        handleSizeChange(val) {
          console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
          console.log(`当前页: ${val}`);
          this.getMemberList(val,this.pageConfig.pageSize)
        },
        //冻结
        handleFreeze(data){
          if(data.isFreeze =='1'){
            Message({
              type:'error',
              message:'该账号已冻结'
            })
          }else{
            MessageBox.confirm('此操作将永久冻结改账号！是否继续？','提示',{
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(()=>{
              reqFreeze(data.userId).then(res=>{
                if(res.code==200){
                  Message({
                    type:'success',
                    message:'冻结成功'
                  })
                  this.getMemberList(this.pageConfig.currentPage,this.pageConfig.pageSize)
                }
              })
            })
          }
        },
        //启用
        handleIsFreeze(data){
          if(data.isFreeze =='0'){
            Message({
              type:'error',
              message:'该账号正常'
            })
          }else{
            MessageBox.confirm('此操作将重新启用该账号！是否继续？','提示',{
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(()=>{
              reqUpFreeze(data.userId).then(res=>{
                if(res.code==200){
                  Message({
                    type:'success',
                    message:'启用成功'
                  })
                  this.getMemberList(this.pageConfig.currentPage,this.pageConfig.pageSize)
                }
              })
            })
          }
        },
    }
}
</script>

<style lang="less" scoped>
.member{
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    .mange{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 10px;
  }
  .block{
      display: flex;
      justify-content: flex-end;
    }
}

</style>