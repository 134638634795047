<template>
  <div class="login">
    <h3>共享数库管理系统登录页</h3>
    <div class="login-item">
        <el-form :rules="rules" label-position="left" ref="form"  label-width="70px" :model="form">
            <el-form-item label="用户名:" prop="name">
                <el-input  placeholder="请输入用户名" v-model="form.name" clearable></el-input>
            </el-form-item>
            <el-form-item label="密码:" prop="password">
                <el-input type="password"  placeholder="请输入密码" v-model="form.password" clearable show-password></el-input>
            </el-form-item>
            <div class="code">
                <el-form-item label="验证码" prop="identifyCode">
                    <el-input v-model="form.identifyCode" placeholder="请输入验证码" style="width: 160px" />
                </el-form-item>
                <div class="img" @click="refreshCode">
                    <Identify :identifyCode="identifyCode"></Identify>
                </div>
            </div>
            <div class="checked">
                <el-checkbox v-model="form.checked">记住密码</el-checkbox>
            </div>
        </el-form>
        <el-button type="primary" style="width:360px;margin-left: 60px;" @click="toIndex">登录</el-button>
    </div>
  </div>
</template>

<script>
import { reqLogin } from '@/api/login';
import { Message } from 'element-ui';
import Identify from '@/components/LoginIdentify/identify.vue'
import { setToken} from '@/utils/auth'
import Cookies from 'js-cookie'
import { encrypt,decrypt } from '@/utils/jsencrypt'
export default {
    data(){
        return{
            identifyCode:'',
            identifyCodes: '0123456789abcdwerwshdjeJKDHRJHKPLMKQ',//验证码随机范围
            form:{
                name:'',
                password:'',
                identifyCode:'',
                checked:true,
            },
            
            rules:{
                name:[
                    { required: true, message: '请输入用户名' }
                ],
                password:[
                    { required: true, message: '请输入密码' }
                ],
                identifyCode:[
                    { required: true, message: '请填写验证码' }
                ]
            }
        }
    },
    components:{
        Identify
    },
    mounted(){
        String.prototype.compare = function(val) {
            return this.toLowerCase() == val.toLowerCase()
        }
        this.refreshCode()
        this.getCookie()
    },
    methods:{
        getCookie(){
            let name = Cookies.get("kd-name");
            let password = Cookies.get("kd-password");
            let checked = Cookies.get('kd-checked')
            this.form = {
                name: name === undefined ? this.form.name : name,
                password: password === undefined ? this.form.password : decrypt(password),
                checked: checked === undefined ? false : Boolean(checked)
            };
        },
        //登录
        toIndex(){
            this.$refs.form.validate((validate)=>{
                if(validate){
                    // this.$router.push('/index')
                    if(this.form.identifyCode.compare(this.identifyCode)){
                        reqLogin(this.form.name,this.form.password).then(res=>{
                            console.log(res,'登录')
                            if(res.data.result.code==200){
                                if(this.form.checked){
                                    Cookies.set("kd-name", this.form.name);
                                    Cookies.set("kd-password", encrypt(this.form.password));
                                    Cookies.set("kd-checked", this.form.checked);
                                }else{
                                    Cookies.remove('kd-name')
                                    Cookies.remove('kd-password')
                                    Cookies.remove('kd-checked')
                                }
                                let token = res.data.result.data.token
                                sessionStorage.setItem('postId',res.data.result.data.postId)
                                sessionStorage.setItem('state',res.data.result.data.state)
                                setToken(token)
                                this.$router.push('/index')
                            }else{
                                Message({
                                    type:'error',
                                    message:'用户名或密码错误！'
                                }) 
                            }
                        })
                    }else{
                        Message({
                            type:'error',
                            message:'验证码错误！'
                        })
                    }
                }
            })
        },
        // 验证码点击刷新方法
        refreshCode() {
            this.identifyCode = ''
        // 4位验证码可以根据自己需要来定义验证码位数
            this.makeCode(this.identifyCodes, 4)
        }, 
        // 随机数
        randomNum(min, max) {
            max = max + 1
            return Math.floor(Math.random() * (max - min) + min)
        },
        // 随机生成验证码字符串
        makeCode(data, len) {
            for (let i = 0; i < len; i++) {
                this.identifyCode += data[this.randomNum(0, data.length - 1)]
            }
        }
    }
}
</script>

<style lang="less" scoped>
.login{
    width: 100vw;
    height: 100vh;
    background: url(@/assets/login_bg.jpg);
    background-size: cover;
    // background-image: linear-gradient(125deg,#55efc4,#a29bfe,#87f);
    // background-size: 500%;
    display: flex;
    flex-direction: column;
    align-items: center;
    h3{
        display: flex;
        justify-content: center;
        font-size: 30px;
        letter-spacing:8px;
        margin-left: 40px;
    }
    .login-item{
        width: 400px;
        height: 400px;
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .code {
        display: flex;
        align-items: center;
        .img{
            width: 160px;
            height: 54px;
            display: flex;
            align-items: center;
            margin-left: 20px;
            margin-bottom: 10px;
            z-index: 10;
            padding: 0 20px;
        }
    }
}
.checked{
    margin-left: 16%;
    margin-bottom: 10px;
}
</style>