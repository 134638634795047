<template>
  <el-dialog
      title="修改"
      :visible.sync="dialogVisible"
      width="40%">
      <el-form ref="form" :rules="rules"  :model="form" label-width="100px">
          <el-form-item label="省份:" prop="province">
            <el-input :disabled="true" placeholder="请输入省份" v-model="form.province"></el-input>
          </el-form-item>
          <el-form-item label="预付:" prop="foundationMoney">
            <el-input placeholder="请输入预付金额" v-model="form.foundationMoney"></el-input>
          </el-form-item>
          <el-form-item label="首重(kg/￥):" prop="firstWeight">
            <el-input placeholder="请输入首重" v-model="form.firstWeight"></el-input>
          </el-form-item>
          <el-form-item label="续重(kg/￥):" prop="continuousWeight">
            <el-input placeholder="请输入手机号" v-model="form.continuousWeight"></el-input>
          </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isStageAdd">取 消</el-button>
        <el-button type="primary" @click="subDefine">确 定</el-button>
      </span>
    </el-dialog>
</template>

<script>


import {reqProFitRatioUpDate} from "@/api/profitratio";
import {Message} from "element-ui";

export default {
  data(){
    return{
      dialogVisible:false,
      form:{
        province:'',
        foundationMoney:'',
        firstWeight:'',
        continuousWeight:''
      },
      rules:{
        province:[{required: true, message: '请输入省份'}],
        foundationMoney:[{required: true, message: '请输入预付'}],
        firstWeight:[{required: true, message: '请输入首重'}],
        continuousWeight:[{required: true, message: '请输入续重'}]
      }
    }
  },
  mounted(){},
  methods:{
    //取消
    isStageAdd(){
      this.$emit('isStageAdd')
    },
    //确定
    subDefine(){
      this.$emit('subDefine')
    }
  }
}
</script>

<style>

</style>
