<template>
  <el-dialog
      title="新增驿站"
      :visible.sync="dialogVisible"
      width="40%">
      <el-form ref="form" :rules="rules"  :model="form" label-width="100px">
          <el-form-item label="驿站名称:" prop="postName">
            <el-input placeholder="请输入驿站名称" v-model="form.postName"></el-input>
          </el-form-item>
          <el-form-item label="联系方式:" prop="postPhone">
            <el-input placeholder="请输入手机号" :max="11" v-model="form.postPhone"></el-input>
          </el-form-item>
          <el-form-item label="负责人:" prop="owner">
            <el-input placeholder="请输入姓名" v-model="form.owner"></el-input>
          </el-form-item>
          <!-- <el-form-item label="驿站地址:" prop="province">
            <el-cascader
              style="width:100%"
              :key="keyValue"
              v-model="value"
              :options="options"
              :props="optionProps"
              @change="handleChange">
            </el-cascader>
          </el-form-item> -->
          <el-form-item label="详细地址:" prop="postAddress">
            <!-- <el-input type="textarea" placeholder="请输入详细地址" v-model="form.postAddress"></el-input> -->
            <avue-input-map class="inputMap" :params="params" placeholder="请选择地图" v-model="formMap" ></avue-input-map>
            <div>
              <el-descriptions>
                <el-descriptions-item class="descriptions" label="经度">{{formMap[0]}}</el-descriptions-item>
                <el-descriptions-item class="descriptions" label="纬度">{{formMap[1]}}</el-descriptions-item>
            </el-descriptions>
            </div>
          </el-form-item>
          
          <el-form-item label="支付宝账号:" prop="aliAccount">
            <el-input placeholder="请输入账号" v-model="form.aliAccount"></el-input>
          </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isStageAdd">取 消</el-button>
        <el-button type="primary" @click="subDefine">确 定</el-button>
      </span>
    </el-dialog>
</template>

<script>
import { getArea } from '@/const/comm'
export default {
  props:{
    // options:{
    //   type:Array,
    //   default(){
    //     return []
    //   }
    // },
  },

  data(){
    return{
      value:[],
      keyValue:0,
      dialogVisible:false,
      optionProps: {
          value:'name',
          label:'name',
          children:'childCity'
      },
      params:{
          zoom: 10,
      },
      formMap:[],
      form:{
        postName:'',
        province:'',
        city:'',
        country:'',
        postAddress:'',
        owner:'',
        postPhone:'',
        aliAccount:'',
      },
      rules:{
        postName: [
            { required: true, message: '请输入驿站名称' }
        ],
        province: [
            { required: true, message: '请输入驿站地址' }
        ],
        postAddress:[
            { required: true, message: '请输入详细地址' }
        ],
        postPhone: [
            { required: true, message: '请输入手机号' }
        ],
        owner: [
            { required: true, message: '请输入负责人姓名'}
        ],
        aliAccount: [
            { required: true, message: '请输入支付宝账号' }
        ]
      },
    }
  },
  methods:{
    handleChange(val) {
        console.log(val);
        let address = val.join('')
        axios.get('https://restapi.amap.com/v3/geocode/geo',{
            params:{
                key:'6c58364e6eb5913a170ec9b4f45d94ad',
                address:address
            }
        }).then(res=>{
          let str = res.data.geocodes[0].location.split(',')
          str.push(address)
          this.formMap = str
          console.log(this.formMap);
        })
        this.form.province = val[0]
        this.form.city = val[1]
        this.form.country = val[2]
    },
    isStageAdd(){
      this.$emit('isStageAdd')
    },
    subDefine(){
      this.$emit('subDefine')
    }
  }
}
</script>

<style>
.avue-dialog .el-dialog{
  width: 600px;
  height: 80%;
}

</style>