import request from '@/utils/request'
import { getToken} from '@/utils/auth'


//登录
export function reqLogin(sysUserName,sysPassword){
    return request({
        url:`/win/login/winLogin`,
        method:'POST',
        params:{
            sysUserName,
            sysPassword
        }
    })
}

//管理员列表
export function reqAdminList(pageSize,pageNo,sysUserId,sysUserName){
    return request({
        url:`/win/login/winAllSysUser/${pageSize}/${pageNo}?sysUserId=${sysUserId}`,
        method:'POST',
        params:{
            sysUserName
        }
    })
}

//管理员添加
export function reqAdminListAdd(obj){
    return request({
        url:`/win/login/winAddUser`,
        method:'POST',
        data:obj
    })
}

//管理员删除
export function reqAdminListDel(sysUserId,currentId){
    return request({
        url:`/win/login/winDelUser`,
        method:'POST',
        params:{
            sysUserId,
            currentId
        }
    })
}

//管理员修改
export function reqAdminListUpDate(obj){
    return request({
        url:`/win/login/winUpdateUser`,
        method:'POST',
        data:obj
    })
}

//管理员 修改密码
export function reqUpDataUser(newPwd,oldPassword){
    return request({
        url:`/win/login/winUpdatePwd`,
        method:'POST',
        headers:{
            token:getToken()
        },
        params:{
            newPwd,
            oldPassword
        }
    })
}

//根据token查询登录用户信息
export function reqAdminToken(){
    return request({
        url:`/win/login/tokenGetUser`,
        method:'POST',
        headers:{
            token:getToken()
        },
    })
}
