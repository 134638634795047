<template>
  <div class="header">
    <el-row class="tac">
      <el-col :span="12">
        <div class="anmite" :class="!this.isCollapse ? 'is-collapse' : 'title'">
          <template v-if="this.isCollapse">
            <h3>后台</h3>
          </template>
          <template v-else>
            <h3>共享数库后台管理系统</h3>
          </template>
        </div>
        <el-menu unique-opened router :default-active="$route.path" class="el-menu-vertical-demo" :collapse="isCollapse"
          background-color="#545c64" text-color="#fff" active-text-color="#ffd04b">

          <el-menu-item @click="getTabMenu(item)" v-for="(item, index) in noChildren" :key="index"
            :index="item.path + ''">
            <i :class="`el-icon-${item.icon}`"></i>
            <span slot="title">{{ item.label }}</span>
          </el-menu-item>
          <el-submenu v-for="item in isChildren" :key="item.label" :index="item.path + ''">
            <template slot="title">
              <i :class="`el-icon-${item.icon}`"></i>
              <span>{{ item.label }}</span>
            </template>
            <el-menu-item-group v-for="em in item.children" :key="em.path" :index="em.path + ''">
              <el-menu-item @click="getTabMenu(em)" :index="em.path + ''">
                <i :class="`el-icon-${em.icon}`"></i>
                <span>{{ em.label }}</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  data() {
    return {
      pathMenu: [

      ],
    }
  },
  created() {
    if (sessionStorage.getItem('state') == 1) {
      this.pathMenu = [{
        path: '/index',
        name: 'index',
        label: '首页',
        icon: 's-home',
        url: 'Index.vue'
      },
      {
        path: '/order',
        name: 'order',
        label: '订单管理',
        icon: 'document',
        url: 'Order.vue'
      },
      //  {
      //   path: '/stageInfo',
      //   name: 'stageInfo',
      //   label: '服务申请',
      //   icon: 'document-copy',
      //   url: 'StageInfo.vue'
      // },
      {
        path: '/user',
        name: 'user',
        label: '骑手管理',
        icon: 'truck',
        url: 'User.vue'
      },
      {
        path: '/member',
        name: 'member',
        label: '会员管理',
        icon: 's-check',
        url: 'Member.vue'
      },
      {
        path: '/stage',
        name: 'stage',
        label: '驿站管理',
        icon: 's-shop',
        url: 'Stage.vue'
      },
      {
        path: '/record',
        name: 'record',
        label: '意见反馈',
        icon: 'chat-line-round',
        url: 'Record.vue'
      },
      {
        label: '财务管理',
        icon: 'coin',
        path: '/financial',
        children: [
          {
            path: '/financial/sustain',
            name: 'sustain',
            label: '驿站结算',
            icon: 'wallet',
            url: 'Sustain.vue'
          },
          // {
          //   path: '/financial/userAccounts',
          //   name: 'userAccounts',
          //   label: '骑手结算',
          //   icon: 'money',
          //   url: 'UserAccounts.vue'
          // },
          {
            path: '/financial/profitRatio',
            name: 'profitRatio',
            label: '设置利润',
            icon: 'money',
            url: 'ProfitRatio.vue'
          },
        ]
      },
      {
        label: '账号管理',
        icon: 'user-solid',
        path: '/account',
        children: [
          {
            path: '/account/adminList',
            name: 'adminList',
            label: '管理员列表',
            icon: 's-fold',
            url: 'AdminList.vue'
          },
        ]
      }]
    } else {
      this.pathMenu = [
        {
          path: '/index',
          name: 'index',
          label: '首页',
          icon: 's-home',
          url: 'Index.vue'
        },
        {
          path: '/order',
          name: 'order',
          label: '订单管理',
          icon: 'document',
          url: 'Order.vue'
        },
        {
          path: '/user',
          name: 'user',
          label: '骑手管理',
          icon: 'truck',
          url: 'User.vue'
        },
        {
          path: '/stageInfo',
          name: 'stageInfo',
          label: '服务申请',
          icon: 'document-copy',
          url: 'StageInfo.vue'
        },
        // {
        //   path: '/member',
        //   name: 'member',
        //   label: '会员管理',
        //   icon: 's-check',
        //   url: 'Member.vue'
        // },
        // {
        //   path: '/stage',
        //   name: 'stage',
        //   label: '驿站管理',
        //   icon: 's-shop',
        //   url: 'Stage.vue'
        // },
        {
          path: '/record',
          name: 'record',
          label: '意见反馈',
          icon: 'chat-line-round',
          url: 'Record.vue'
        },
        {
          label: '财务管理',
          icon: 'coin',
          path: '/financial',
          children: [
            // {
            //   path: '/financial/sustain',
            //   name: 'sustain',
            //   label: '驿站结算',
            //   icon: 'wallet',
            //   url: 'Sustain.vue'
            // },
            {
              path: '/financial/userAccounts',
              name: 'userAccounts',
              label: '骑手结算',
              icon: 'money',
              url: 'UserAccounts.vue'
            },
            {
              path: '/financial/profitRatio',
              name: 'profitRatio',
              label: '设置利润',
              icon: 'money',
              url: 'ProfitRatio.vue'
            },
          ]
        },
        // {
        //   label:'账号管理',
        //   icon:'user-solid',
        //   path:'/account',
        //   children:[
        //     {
        //       path: '/account/adminList',
        //       name: 'adminList',
        //       label: '管理员列表',
        //       icon: 's-fold',
        //       url: 'AdminList.vue'
        //     },
        //   ]
        // }
      ]
    }

  },
  mounted() {
    // console.log(this.$router.history.current,'333')
    // // this.getPath(this.$route.meta.label)
    // console.log(this.$route.name)
    // // console.log(this.pathMenu[8].children[1],'123');
  },
  computed: {
    ...mapState({
      isCollapse: state => state.tab.isCollapse
    }),
    //没有子菜单
    noChildren() {
      return this.pathMenu.filter(item => !item.children)
    },
    //有子菜单
    isChildren() {
      return this.pathMenu.filter(item => item.children)
    },
  },
  methods: {
    ...mapMutations(['updateMenu', 'headerMenu']),
    getTabMenu(item) {
      console.log(this.$route, '@@')
      if (this.$route !== item.path && !(this.$route.path === '/index' && (item.path === '/'))) {
        this.$router.push(item.path)
      }
      this.updateMenu(item)
      this.headerMenu(item)
    },
  }
}
</script>

<style lang="less" scoped>
.header {
  overflow: hidden;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  height: 100%;
  min-height: calc(100vh - 60px);

}

.el-menu {
  height: 100vh;
  // min-height: 100vh;
  height: 100%;
  // overflow-y: hidden;
  border-right: none;
  // margin-top: 60px;
}

.is-collapse {
  width: 200px;
  height: 60px;
  background-color: #545c64;
  // position: fixed;
  top: 0;
  z-index: 20;

  h3 {
    margin: 0;
    padding: 0;
    font-size: 16px;
    color: #fff;
    text-align: center;
    line-height: 48px;
  }
}

.title {
  width: 64px;
  height: 60px;
  background-color: #545c64;
  // position: fixed;
  color: white;
  top: 0;
  z-index: 20;
  text-align: center;
}

.anmite {
  transition: all 0.5s cubic-bezier(0, 0, 0.5, 0.5);

  overflow: hidden;
}
</style>
