import request from '@/utils/request'

//骑手提现申请列表
export function reqUserAccountsList(pageSize,pageNo,sysUserId,fullName){
    return request({
        url:`/win/courier/courierWithdrawPage/${pageSize}/${pageNo}?sysUserId=${sysUserId}`,
        method:'POST',
        params:{
            fullName
        }
    })
}

//驿站提现申请列表
export function reqSustainList(pageSize,pageNo,phone){
    return request({
        url:`/win/post/postClosing/${pageSize}/${pageNo}`,
        method:'POST',
        params:{
            phone
        }
    })
}

//骑手提现 同意，拒绝
export function reqUserAgree(obj){
    return request({
        url:`/win/courier/updateStatus`,
        method:'POST',
        data:obj
    })
}

//比例设置
export function reqFind(){
    return request({
        url:`/win/proportion/find`,
        method:'POST',
    })
}
//比例设置修改
export function reqFindUpdate(obj){
    return request({
        url:`/win/proportion/update`,
        method:'POST',
        data:obj
    })
}

//驿站结算
export function reqBeClosing(postId){
    return request({
        url:`/win/post/beClosing`,
        method:'POST',
        params:{
            postId
        }
    })
}

//获取利润比例
export function ProFitRatio(obj){
    return request({
        url:`/fm/profitratio/ProFitRatioA`,
        method:'POST',
        data:obj
    })
}

