<template>
    <el-form ref="form" class="form_item" :model="form" label-width="80px">
        <template v-if="SearchCrud.obj==='订单'">
            <!-- <el-form-item label="支付状态:">
                <el-select v-model="form.searchPay" clearable placeholder="请选择">
                <el-option label="未支付" value="0"></el-option>
                <el-option label="已支付" value="1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="寄件类型:">
                <el-select v-model="form.searchStatus" clearable placeholder="请选择">
                <el-option label="寄单件" value="1"></el-option>
                <el-option label="寄多件" value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="订单状态:">
                <el-select v-model="form.searchOrder" clearable placeholder="请选择">
                <el-option
                    v-for="item in optionStatus"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
                </el-select>
            </el-form-item> -->
            <el-form-item label="订单号:">
                <el-input clearable  placeholder="请输入订单号" v-model="form.searchInput"></el-input>
            </el-form-item>
        </template>
        <template v-if="SearchCrud.obj==='驿站'">
            <el-form-item label="状态:">
                <el-select v-model="form.searchStatus" clearable placeholder="请选择">
                    <el-option
                        v-for="item in optionStatus"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="关键字:">
                <el-input clearable  placeholder="请输入驿站名称" v-model="form.searchInput"></el-input>
            </el-form-item>
        </template>
        <el-form-item>
            <el-button type="primary" @click="inputChange">查询</el-button>
        </el-form-item>
    </el-form>
</template>

<script>
export default {
    props:{
        optionStatus:{
            type:Array,
            default(){
                return []
            }
        },
        SearchCrud:{
            type:Object,
            default(){
                return {
                    obj:'0'
                }
            }
        }
    },
    data(){
        return{
            form:{
                searchPay:'',
                searchOrder:'',
                searchInput:'',
                searchStatus:''
            },
        }
    },
    methods:{
        inputChange(){
            this.$emit('inputChange',this.form)
        }
    }
}
</script>

<style>

</style>