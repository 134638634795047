<template>
  <div>
    <el-row>
      <el-col :span="24" style="padding-left: 10px">
        <div class="num">
          <el-card
            v-for="(item, index) in countData"
            :key="index"
            :body-style="{ display: 'flex' }"
          >
            <i
              class="iocn"
              :class="`el-icon-${item.icon}`"
              :style="{ background: item.color }"
            ></i>
            <div class="solid">
              <p class="soli_ps">{{ item.name }}</p>
              <p class="soli_p">
                {{ item.value }}<span class="provide">{{ item.provide }}</span>
              </p>
              <div class="soli_ps">
                较昨日
                <i :class="`el-icon-top`" style="color: red"></i>
                {{ item.valueNum }}{{ item.provide }}
              </div>
            </div>
          </el-card>
        </div>
      </el-col>
      <el-col :span="24" style="padding-left: 10px">
        <el-card style="height: 300px">
          <div ref="mains" style="height: 300px"></div>
        </el-card>
      </el-col>
      <!-- <el-col :span="12" style="padding-left: 10px;">
        <el-card style="height:300px">
          <div ref="sector" style="height:240px"></div>
        </el-card>
    </el-col> -->
      <el-col :span="24" style="padding-left: 10px; margin-top: 10px">
        <el-card style="height: 300px">
          <div ref="shops" style="height: 260px"></div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { countData } from '@/const/index/index'
import * as echarts from 'echarts';
import { reqAllCourier, reqMonthCourier, reqMonthUser, reqMonthOrder, reqOrderTotalMoney, reqMonthMender } from '@/api/index'
export default {
  data () {
    return {
      countData:countData,
      legendSelectChangedIndex: 0,
      canvassData: [],//每月订单量
      canvassMoney: [],//每月营业额
      canvassTime: [],//每月时间
      xAxis: [],
      xAxis01: [],
      xAxis02: [],
      monthMender: [],//每月用户量
      monthUser: [],//每月骑手量
      monthStage: [],//每月驿站量
      hostData: [
        {
          data: [150, 230, 224, 218, 135, 147, 260],
          type: 'line'
        }
      ]
    }
  },
  mounted () {
      this.getAllCourier()
      this.getMonthCourier()
      this.getMonthUser()
      this.getMonthOrder()
      this.getMonthMender()
      this.getOrderTotalMoney()

  },
  methods: {
    //查骑手,驿站,用户数量 新增数量
    getAllCourier () {
      reqAllCourier().then(res => {
        console.log(res, '骑手数量')
        this.countData[0].value = res.data.allOrder
        this.countData[2].value = res.data.allUser
        this.countData[3].value = res.data.allCourier
        this.countData[4].value = res.data.allPost
        this.countData[0].valueNum = res.data.todayOrder
        this.countData[2].valueNum = res.data.todayUser
        this.countData[3].valueNum = res.data.todayCourier
        this.countData[4].valueNum = res.data.todayPost
        this.getCharts()
      })
    },
    //每月驿站数量
    getMonthCourier () {
      reqMonthCourier().then(res => {
        console.log(res, '每月')
        let list = res.data.list
        let data = []
        list.forEach(v => {
          data.push(v.countNum)
          this.xAxis.unshift(v.createTime)
        })
        for (let i = 1; i < data.length; i++) {
          data[i] += data[i - 1]
        }
        this.monthStage = data
      })
    },
    //每月骑手数量
    getMonthUser () {
      reqMonthUser().then(res => {
        let list = res.data.list
        let data = []
        list.forEach(v => {
          data.push(v.countNum)
          this.xAxis01.unshift(v.createTime)
        })
        for (let i = 1; i < data.length; i++) {
          data[i] += data[i - 1]
        }
        this.monthUser = data
      })
    },
    //每月用户数量
    getMonthMender () {
      reqMonthMender().then(res => {
        console.log(res, '用户-------------')
        let list = res.data.list
        let data = []
        list.forEach(v => {
          data.push(v.countNum)
          this.xAxis02.unshift(v.createTime)
        })
        for (let i = 1; i < data.length; i++) {
          data[i] += data[i - 1]
        }
        this.monthMender = data
      })
    },
    //每月订单数量及营业额
    getMonthOrder () {
      reqMonthOrder().then(res => {
        console.log(res, 'oooo')
        this.canvassData = res.data.list.map(v => v.countNum)
        this.canvassMoney = res.data.list.map(v => this.numFilter(v.totalMoney))
        this.canvassTime = res.data.list.map(v => v.createTime)
        console.log(this.canvassMoney)
      })
    },
    //订单总营业额
    getOrderTotalMoney () {
      reqOrderTotalMoney().then(res => {
        console.log(res, '总营业额')
        this.countData[1].value =this.numFilter(res.data.totalMoney) 
        this.countData[1].valueNum = this.numFilter(res.data.todayMoney)
      })
    },
    numFilter(value) {
      // 截取当前数据到小数点后三位
      let tempVal = parseFloat(value).toFixed(3)
      let realVal = tempVal.substring(0, tempVal.length - 1)
      return realVal
    },
    //图表
    getCharts () {
      // 柱状图
      // 基于准备好的dom，初始化echarts实例
      const shops = echarts.init(this.$refs.mains)
      var canvassList = {
        legend: {
          // 图例文字颜色
          textStyle: {
            color: "#333",
          },
        },
        grid: {
          left: "20%",
        },
        title: {
          text: '月份订单量柱状图(取12个月)'
        },
        // 提示框
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category", // 类目轴
          data: this.canvassTime,
        },
        yAxis: [
          {
            type: "value",
          },
        ],
        color: ["#2ec7c9", "#b6a2de"],
        series: [
          {
            name: '订单量',
            data: this.canvassData,
            type: 'bar'
          },
          {
            name: '服务金额',
            data: this.canvassMoney,
            type: 'bar'
          }
        ],
      }
      shops.setOption(canvassList)

      //折线图
      var myChart = echarts.init(this.$refs.shops);
      var option;
      option = {
        title: {
          text: '月服务流量折线图(取12个月)'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['用户数量', '骑手数量', '驿站数量']

        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        //.length>=this.xAxis01.length||this.xAxis.length>=this.xAxis02.length?this.xAxis:this.xAxis01.length>=this.xAxis02.length?this.xAxis01:this.xAxis02
        xAxis: [
          {
            data: this.xAxis
          },

        ],
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '用户数量',
            type: 'line',
            stack: 'Total',
            data: this.monthMender

          },
          {
            name: '骑手数量',
            type: 'line',
            stack: 'Total',
            data: this.monthUser
          },
          {
            name: '驿站数量',
            type: 'line',
            stack: 'Total',
            data: this.monthStage
          },
        ]
      };

      option && myChart.setOption(option);
    }
  }
}
</script>

<style lang="less" scoped>
p {
  padding: 0;
  margin: 0;
}
.user {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
  img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-right: 40px;
  }
  .user_info {
    .info_p {
      margin-bottom: 10px;
      font-size: 32px;
    }
    .info_ps {
      color: #999;
    }
  }
}
.login {
  p {
    line-height: 28px;
    font-size: 14px;
    color: #999;

    span {
      margin-left: 60px;
      color: #333;
    }
  }
}
.master-order {
  margin-top: 40px;
  .master-h {
    padding: 0;
    margin: 0;
    display: flex;
    font-weight: 600;
    justify-content: center;
  }
}
.num {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .el-card {
    position: relative;
    width: 19%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    .provide {
      font-size: 12px;
    }
    .iocn {
      position: absolute;
      top: 23px;
      left: 20px;
      width: 40px;
      height: 40px;
      font-size: 28px;
      line-height: 40px;
      text-align: center;
      // margin-right: 50px;
      color: #fff;
    }
    .solid {
      // width: 90px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .soli_p {
        height: 30px;
        line-height: 30px;
        font-size: 32px;
        margin-top: 5px;
        margin-bottom: 5px;
      }
      .soli_ps {
        font-size: 14px;
        color: #999;
        text-align: center;
      }
    }
  }
}
.grour {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  .el-card {
    width: 48%;
  }
}
</style>
