<template>
  <div class="user">
    <el-card>
      <div class="mange">
        <div></div>
        <div>
          <el-input style="width:200px;margin-right: 10px;" clearable v-model="formSeas.name"
            placeholder="请输入姓名"></el-input>
          <el-input style="width:200px;margin-right: 10px;" clearable v-model="formSeas.phone"
            placeholder="请输入手机号"></el-input>
          <el-button type="primary" @click="inputChange">查询</el-button>
          <el-button @click="canlaChange">重置</el-button>
        </div>
      </div>
      <div class="common-tatbel">
        <table-Data :tableCrud="{ index: '骑手' }" :tableData="tableData" :config="config" @handleFreeze="handleFreeze"
          @handleIsFreeze=handleIsFreeze>
        </table-Data>
        <div class="block">
          <Pagination ref="pagination" :pageConfig="pageConfig" @handleCurrentChange="handleCurrentChange">
          </Pagination>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination/Pagination';
import tableData from '@/components/TableData/tableData'
import { mapState } from 'vuex'
import { MessageBox, Message } from 'element-ui';
import { reqUserList, reqFreeze, reqUpFreeze } from '@/api/user'
export default {
  components: {
    tableData,
    Pagination
  },
  data() {
    return {
      input: '',
      dialogVisible: false,
      userIndex: 0,
      index: -1,
      tableData: [],
      config: [
        {
          prop: 'courierId',
          label: '骑手Id'
        },
        {
          prop: 'postName',
          label: '驿站名称'
        },
        {
          prop: 'fullName',
          label: '骑手姓名'
        },
        {
          prop: 'avatar',
          label: '头像'
        },
        {
          prop: 'province',
          label: '省'
        },
        {
          prop: 'city',
          label: '市'
        },
        {
          prop: 'country',
          label: '区'
        },
        // {
        //   prop:'aliAccount',
        //   label:'支付宝账号'
        // },
        {
          prop: 'phone',
          label: '手机号'
        },
        // {
        //   prop:'sex',
        //   label:'性别'
        // },
        {
          prop: 'isFreeze',
          label: '账号状态'
        },
        {
          prop: 'createTime',
          label: '加入时间'
        }
      ],
      pageConfig: {
        //当前页数
        currentPage: 1,
        //每页显示数
        pageSize: 10,
        //总数据
        total: 10,
      },
      formSeas: {
        phone: '',
        sysUserId: sessionStorage.getItem('postId'),
        name: ''
      }

    }
  },
  mounted() {
    this.getUserList(this.pageConfig.currentPage, this.pageConfig.pageSize, this.formSeas)
  },
  computed: {

  },
  methods: {
    //查询 骑手列表
    getUserList(pageSize, pageNo, query) {
      reqUserList(pageSize, pageNo, query).then(res => {
        console.log(res, '骑手列表')
        this.pageConfig.currentPage = res.data.page.currPage
        this.pageConfig.total = res.data.page.total
        this.tableData = res.data.page.data
      })
    },
    //模糊查询 
    inputChange() {
      this.getUserList(1, this.pageConfig.pageSize, this.formSeas)
    },
    //重置
    canlaChange() {
      this.formSeas = {
        phone: '',
        sysUserId: sessionStorage.getItem('postId'),
        name: ''
      }
      this.getUserList(1, this.pageConfig.pageSize, this.formSeas)
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    //分页
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.getUserList(val, this.pageConfig.pageSize, this.formSeas)
    },
    //冻结
    handleFreeze(data) {
      if (data.isFreeze == '1') {
        Message({
          type: 'error',
          message: '该账号已冻结'
        })
      } else {
        MessageBox.confirm('此操作将永久冻结改账号！是否继续？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          reqFreeze(data.courierId).then(res => {
            if (res.code == 200) {
              Message({
                type: 'success',
                message: '冻结成功'
              })
              this.getUserList(this.pageConfig.currentPage, this.pageConfig.pageSize, sessionStorage.getItem('postId'))
            }
          })
        })
      }
    },
    //启用
    handleIsFreeze(data) {
      if (data.isFreeze == '0') {
        Message({
          type: 'error',
          message: '该账号正常'
        })
      } else {
        MessageBox.confirm('此操作将重新启用该账号！是否继续？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          reqUpFreeze(data.courierId).then(res => {
            if (res.code == 200) {
              Message({
                type: 'success',
                message: '启用成功'
              })
              this.getUserList(this.pageConfig.currentPage, this.pageConfig.pageSize, sessionStorage.getItem('postId'))
            }
          })
        })
      }
    },

  }
}
</script>

<style lang="less" scoped>
.el-table {
  height: 660px;
}

.user {
  display: flex;
  flex-direction: column;
  margin-left: 10px;

  .mange {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 10px;
  }

  .block {
    display: flex;
    justify-content: flex-end;
  }
}
</style>