import Vue from "vue"
import VueRouter from "vue-router"
Vue.use(VueRouter)

import Menu from '@/views/Menu.vue'
import Index from '@/views/index/Index.vue'
import Order from '@/views/order/Order.vue'
import User from '@/views/user/User.vue'
import Sustain from '@/views/sustain/Sustain.vue'
import Stage from '@/views/stage/Stage.vue'
import UserAccounts from '@/views/userAccounts/UserAccounts.vue'
import Personal from '@/views/personal/Personal.vue'
import Member from '@/views/member/Member.vue'
import Login from '@/views/login/Login.vue'
import AdminList from '@/views/adminList/AdminList.vue'
import ProfitRatio from '@/views/proFitRatio/ProfitRatio.vue'
import StageInfo from '@/views/stageInfo/StageInfo.vue'
import Record from '@/views/record/Record.vue'
import Wrong from '@/views/404.vue'

const routes = [
    {
        path:'/' ,
        component :Menu,
        name:'Menu',
        redirect:'/index',
        children:[
            { path: '/index',name:'index', component: Index ,meta:{ label:'首页'}},
            { path: '/user',name:'user', component: User ,meta:{ label:'骑手管理'}},
            { path: '/order',name:'order', component: Order ,meta:{ label:'订单管理'}},
            { path: '/stage',name:'stage', component: Stage ,meta:{ label:'驿站管理'}},
            { path: '/stageInfo',name:'stageInfo', component: StageInfo ,meta:{ label:'服务申请'}},
            { path: '/financial/sustain',name:'sustain', component: Sustain ,meta:{ label:'驿站结算'}},
            { path: '/financial/userAccounts',name:'userAccounts', component: UserAccounts ,meta:{ label:'骑手结算'}},
            { path: '/account/personal',name:'personal', component: Personal ,meta:{ label:'个人中心'}},
            { path: '/member',name:'member', component: Member ,meta:{ label:'会员管理'}},
            { path: '/account/adminList',name:'adminList', component: AdminList ,meta:{ label:'管理员列表'}},
            { path: '/financial/profitRatio',name:'profitRatio', component: ProfitRatio ,meta:{ label:'设置利润'}},
            { path: '/record',name:'record', component: Record ,meta:{ label:'意见反馈'}},
        ]
    },
    {
        path:'/login',
        name:'login',
        component:Login
    },
    {
      path:'/404',
      name:'404',
      component:Wrong
    },
    {    
      path: '*',
      redirect: '/404', 
      hidden: true 
    }
  ]

const router = new VueRouter({
    routes,
    data() {
        return {
            
        }
    },
})
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location){
return originalPush.call(this , location).catch(err=>err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location){
return originalReplace.call(this , location).catch(err=>err)
}

export default router