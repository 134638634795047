<template>
  <div>
    <el-container>
      <el-aside class="aside" width="auto">
        <ConnMenu></ConnMenu>
      </el-aside>
      <el-container class="container">
        <el-header style="padding:0">
          <ConnHeader :user="user"></ConnHeader>
        </el-header>
        <el-main style="padding:10px">
          <ConnTab></ConnTab>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import ConnTab from '../components/ConnTab.vue'
import ConnHeader from '../components/ConnHeader.vue'
import ConnMenu from '../components/ConnMenu.vue'
import { removeToken } from '@/utils/auth'
import { reqAdminToken } from '@/api/login'
export default {
  data() {
    return {
      user: {}
    }
  },
  components: {
    ConnMenu,
    ConnHeader,
    ConnTab
  },
  mounted() {
    reqAdminToken().then(res => {
      console.log(res.data.user);
      if (res) {
        this.user = res.data.user
        this.$store.commit('userAdmin', res.data.user)
      } else {
        removeToken()
        this.$router.push('/login')
      }
    })
  }
}
</script>

<style scoped>
.aside {
  position: relative;
}

.container {
  /* margin-left: 200px; */
  /* position: absolute;
  left:150px */
}
</style>